import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_SEARCH_API_BASE_URL;

axios.interceptors.response.use(function (response) {
  return response;
});

const responseData = (response) => response.data;

const requests = {
  get: (url, accessToken) =>
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(responseData),
  post: (url, body, accessToken) =>
    axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(responseData),
  put: (url, body, accessToken) =>
    axios
      .put(url, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(responseData),
  delete: (url, accessToken) =>
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(responseData),
};

export default requests;
