import Forbidden from "../common/Forbidden";
import { Link } from "react-router-dom";
import React from "react";

export default function ServerError({ error, hideRefresh, pathname }) {
  const { errorCode, code } = { ...error };
  const getTemplate = () => {
    if (errorCode === "no_network_connectivity") {
      return (
        <React.Fragment>
          <h2 className="lead text-center text-danger">
            NO NETWORK CONNECTIVITY
          </h2>
          <p className="text-center">Please check your internet connection.</p>
        </React.Fragment>
      );
    }

    if (code === "ERR_NETWORK") {
      return (
        <React.Fragment>
          <h2 className="lead text-center text-danger">Network Error</h2>
          <p className="text-center">
            Something went wrong, please try again later or contact support.
          </p>
        </React.Fragment>
      );
    }

    if (error?.response != null) {
      if (error.response.status === 409) {
        return (
          <React.Fragment>
            <h1 className="display-3 text-center text-danger">409</h1>
            <h2 className="lead text-center text-danger">CONFLICT</h2>
            <p className="text-center">
              Unable to perform the requested operation as there is another
              operation in progress, please try again.
            </p>
          </React.Fragment>
        );
      }
      if (error.response.status === 404) {
        return (
          <React.Fragment>
            <h1 className="display-3 text-center text-danger">404</h1>
            <h2 className="lead text-center text-danger">
              {error.response?.data?.message
                ? error.response?.data?.message
                : "Not Found"}
            </h2>
            <p className="text-center">
              Sorry, the page you are looking for doesn't exist or has been
              moved
            </p>
          </React.Fragment>
        );
      }
      if (error.response.status === 403) {
        return (
          <Forbidden
            error={error}
            hideRefresh={hideRefresh}
            pathname={pathname}
          ></Forbidden>
        );
      }
    }

    return (
      <React.Fragment>
        <h1 className="display-3 text-center text-danger">500</h1>
        <h2 className="lead text-center text-danger">INTERNAL SERVER ERROR</h2>
        <p className="text-center">
          Something went wrong, please try again later or contact support.
        </p>

        {pathname ? (
          <p className="text-center">
            <button
              onClick={() => {
                window.location.href = pathname;
              }}
              className="btn btn-primary"
            >
              Refresh
            </button>
          </p>
        ) : (
          <p className="text-center">
            <Link to="/" className="btn btn-primary">
              Home
            </Link>
          </p>
        )}
      </React.Fragment>
    );
  };

  return <div className="col-12 mt-4">{getTemplate()}</div>;
}
