import api from "./revenuePlannerApi";

const path = "/project-categories";

const projectCategory = {
  list: (accessToken) => api.get(path, accessToken),
  get: (accessToken, id) => api.get(`${path}/${id}`, accessToken),
  post: (accessToken, body) => api.post(path, body, accessToken),
  put: (accessToken, id, body) => api.put(`${path}/${id}`, body, accessToken),
  delete: (accessToken, id) => api.delete(`${path}/${id}`, accessToken),
};

export default projectCategory;
