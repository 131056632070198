import { ReactComponent as DeleteIcon } from "../../components/icons/trash_icon.svg";
import { ReactComponent as DetailIcon } from "../../components/icons/details_icon.svg";
import { ReactComponent as EditIcon } from "../../components/icons/pencil_icon.svg";
import OverFlowToolTip from "../common/OverFlowToolTip";
import React from "react";
import { formatDate } from "../common/utilities";
import { formatNumber } from "../common/formatHelper";
import { isTrue } from "../common/utilities";
import projectSteps from "../../constants/projectSteps";

const ProjectCard = (props) => {
  const getTimeHorizonTemplate = (project) => {
    const timeHorizonValues = project.projectTimeHorizon;

    const timeHorizonLabels = timeHorizonValues.map((timeHorizon) => {
      return (
        <th
          scope="col"
          className="text-end"
          key={`${project.id}-${timeHorizon.label}`}
        >
          <div style={{ width: "8rem" }}>{timeHorizon.label}</div>
        </th>
      );
    });

    const timeHorizon = props.configuration.attributeConfigurations.map((c) => {
      return (
        <tr key={c.attributeName}>
          {c.displayName ? (
            <th scope="col">
              <div>
                <OverFlowToolTip>{c.displayName}</OverFlowToolTip>
              </div>
            </th>
          ) : null}
          {timeHorizonValues.map((timeHorizon) => {
            return (
              <td
                className="text-end"
                key={
                  c.displayName
                    ? `${c.displayName}-${timeHorizon.formattedTimeBucket}`
                    : `${timeHorizon.formattedTimeBucket}`
                }
              >
                <div style={{ width: "8rem" }}>
                  <OverFlowToolTip>
                    {formatNumber(
                      timeHorizon[c.attributeName.toLowerCase()],
                      props.configuration.decimalPlaces
                    )}
                  </OverFlowToolTip>
                </div>
              </td>
            );
          })}
        </tr>
      );
    });

    return (
      <div className="table-responsive scrollbar horizontal">
        <div className="scrollbar-content">
          <div className={`col-${timeHorizonValues.length}`}>
            <table
              className="table table-lg table-striped-custom table-borderless table-header-border"
              key={`${project.id}-time-horizon`}
            >
              <thead>
                <tr>
                  <th scope="col">
                    <div className="text-start">
                      {props.configuration.attributeDisplayNameHeader != null
                        ? props.configuration.attributeDisplayNameHeader
                        : "Name"}
                    </div>
                  </th>
                  {timeHorizonLabels}
                </tr>
              </thead>
              <tbody>{timeHorizon}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const createProjectCard = (project) => {
    if (!props.configuration) {
      return;
    }
    return (
      <div className="row" key={project.id}>
        <div className="col-12 mb-3">
          <div className="card shadow-sm rounded-extra-lg">
            <div className="card-body list-card">
              <div className="flex">
                <div className="name flex">{project.name}</div>
                <div className="flex flex-end">
                  {props.currentStep !== projectSteps.Details ? (
                    <button
                      className="button me-2 circle detail"
                      onClick={() => props.handleDetailProject(project)}
                    >
                      <DetailIcon />
                    </button>
                  ) : (
                    ""
                  )}

                  {props.hasWritePermissions && props.hasWritePermissions() ? (
                    <button
                      className="button me-2 circle"
                      onClick={() => props.handleEditProject(project)}
                      disabled={!props.hasWritePermissions()}
                    >
                      <EditIcon />
                    </button>
                  ) : null}

                  {props.hasDeletePermissions &&
                  props.hasDeletePermissions() ? (
                    <button
                      className="button danger circle"
                      onClick={() => props.handleDelete(project)}
                    >
                      <DeleteIcon />
                    </button>
                  ) : null}
                </div>
              </div>
              {props.currentStep === projectSteps.Details ? (
                <div className="font-weight-medium">Basic Details</div>
              ) : (
                ""
              )}
              <div className="list-card values">
                {/* <div key={project.id} className="value-container">
                      <div className="key">Id</div>
                      <div className="value">{project.id}</div>
                    </div> */}

                {isTrue(props.configuration?.enableProjectWorkflowStatus) &&
                project.workflowStatus != null ? (
                  <div className="value-container">
                    <div className="key">Workflow Status</div>
                    <span className={`value`}>{project.workflowStatus}</span>
                  </div>
                ) : null}
                <div className="value-container">
                  <div className="key">Category</div>
                  <div className="value">{project.category.name}</div>
                </div>
                <div className="value-container">
                  <div className="key">UoM</div>
                  <div className="value">{project.uoM}</div>
                </div>
                <div className="value-container">
                  <div className="key">Currency</div>
                  <div className="value">
                    {project.currency ? project.currency : "N/A"}
                  </div>
                </div>

                <div className="value-container">
                  <div className="key">Valid From</div>
                  <div className="value">{formatDate(project.validFrom)}</div>
                </div>
                <div className="value-container">
                  <div className="key">Valid To</div>
                  <div className="value">{formatDate(project.validTo)}</div>
                </div>
                <div className="value-container">
                  <div className="key">Project Type</div>
                  <div className="value">
                    {project.isFileUpload ? "File Upload" : "Manual"}
                  </div>
                </div>
                <div className="value-container">
                  <div className="key">Status</div>
                  <span className="value">{project.status}</span>
                </div>
              </div>
              {props.currentStep === projectSteps.Details ? (
                <div className="pb-2">
                  <div className="label">Description</div>
                  <p className="description">{project.description}</p>
                </div>
              ) : (
                ""
              )}
              {props.currentStep === projectSteps.Details &&
              project.projectAttributeDetails &&
              project.projectAttributeDetails.length > 0 ? (
                <React.Fragment>
                  <div className="font-weight-medium">Attributes</div>
                  <div className="list-card values">
                    {/* <div key={project.id} className="value-container">
                      <div className="key">Id</div>
                      <div className="value">{project.id}</div>
                    </div> */}

                    {project.projectAttributeDetails.map((pa) => {
                      return (
                        <div className="value-container">
                          <div className="key">{pa.attribute.name}</div>
                          <span className="value">{pa.attribute.value}</span>
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              ) : null}

              {project.projectTimeHorizon != null &&
              project.projectTimeHorizon.length ? (
                <React.Fragment>
                  <div className="font-weight-medium">Time Horizon</div>
                  {getTimeHorizonTemplate(project)}
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <React.Fragment>{createProjectCard(props.project)}</React.Fragment>;
};

export default ProjectCard;
