import React, { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";

const SignInView = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [name, setName] = useState(null);
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (activeAccount) {
      setName(activeAccount.name);
    } else {
      setName(null);
    }
  }, [activeAccount]);

  const handleSignin = (signType) => {
    if (signType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (signType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  const handleSignout = (signType) => {
    if (signType === "popup") {
      instance.logoutPopup();
    } else if (signType === "redirect") {
      instance.logoutRedirect();
    }
  };

  if (isAuthenticated) {
    return (
      <React.Fragment>
        {name ? (
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <span
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {name}
              </span>
              <div className="dropdown-menu">
                <button
                  type="submit"
                  className="dropdown-item font-weight-medium"
                  onClick={() => handleSignout("redirect")}
                >
                  Sign out
                </button>
              </div>
            </li>
          </ul>
        ) : null}
      </React.Fragment>
    );
  } else if (
    inProgress !== InteractionStatus.Startup &&
    inProgress !== InteractionStatus.HandleRedirect
  ) {
    return (
      <ul className="navbar-nav">
        <li className="nav-item">
          <button
            type="submit"
            className="button extra-small"
            onClick={() => handleSignin("redirect")}
          >
            Sign in
          </button>
        </li>
      </ul>
    );
  } else {
    return null;
  }
};

export default SignInView;
