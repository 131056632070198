import React, { useState } from "react";

import Error from "../common/ServerError";
import projectApi from "../../api/projectApi";
import { tokenRequest } from "../../authConfig";
import { useForm } from "react-hook-form";
import { useMsal } from "@azure/msal-react";

const ProcessProject = (props) => {
  const { handleSubmit, reset } = useForm();
  const { instance, accounts } = useMsal();
  const [result, setResult] = useState();
  const [error, setError] = useState();
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleProcessSubmit = () => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    setResult(null);
    setErrorMessages([]);
    setError(null);
    setLoading(true);

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;

        (async () => {
          await projectApi
            .processExpired(accessToken)
            .then((data) => {
              setError(null);
              setErrorMessages([]);
              setResult(data);
              reset();
            })
            .catch((e) => {
              console.log(e);
              if (e.response?.status === 400 && e.response?.data?.errors) {
                let errors = [];
                for (const [key, value] of Object.entries(
                  e.response.data.errors
                )) {
                  errors.push({ name: key, value: value });
                }
                setErrorMessages(errors);
              } else {
                setError(e);
              }
            })
            .finally(() => setLoading(false));
        })();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const onSubmit = async (data) => {
    handleProcessSubmit();
  };

  return (
    <div className="row">
      <div className="col-12 my-3">
        <h4>Process Projects</h4>
      </div>
      <div className="col-6">
        <div className="card shadow rounded-extra-lg">
          <div className="card-body">
            {error ? (
              <Error error={error} />
            ) : (
              <React.Fragment>
                <div className="my-3">
                  <h4>Process Expired Projects</h4>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label className="form-label">
                      Update expired(Valid To date is in the past) projects
                      status
                    </label>
                  </div>
                  <div className="form-row">
                    <div className="form-group col">
                      <button
                        type="submit"
                        className="button primary me-2"
                        disabled={loading}
                      >
                        Start
                        {loading ? (
                          <span className="disabled ms-2">
                            <span
                              className="spinner-grow spinner-grow-1 me-1"
                              role="status"
                            ></span>
                            <span
                              className="spinner-grow  spinner-grow-2 me-1"
                              role="status"
                            ></span>
                            <span
                              className="spinner-grow me-1"
                              role="status"
                            ></span>
                          </span>
                        ) : null}
                      </button>
                    </div>
                  </div>
                </form>

                {result ? (
                  <div className="mt-1 text-success">{result}</div>
                ) : null}

                <div>
                  {errorMessages && errorMessages.length > 0 ? (
                    <div className="">
                      {errorMessages.map((e) => {
                        return (
                          <div key={e.name} className="mb-2 text-danger">
                            {e.value}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessProject;
