import actionType from "../../constants/actionType";
import currency from "../../constants/currency";
import unitOfMeasurements from "../../constants/unitOfMeasurements";
import { useLocation } from "react-router-dom";

const URLHelper = () => {
  const location = useLocation();

  function getSearchParams() {
    return new URLSearchParams(location.search);
  }

  function getFilterDataFromQueryString(searchParams) {
    searchParams = searchParams ? searchParams : getSearchParams();

    const data = [];

    let currentKey = null;
    searchParams.forEach((_, key) => {
      if (
        key !== currentKey &&
        key !== unitOfMeasurements.UOM &&
        key !== currency.CURRENCY &&
        key !== actionType.Edit
      ) {
        currentKey = key;

        data.push({
          items: searchParams.getAll(key),
          field: key,
        });
      }
    });

    return data;
  }

  return [getFilterDataFromQueryString, getSearchParams];
};

export default URLHelper;
