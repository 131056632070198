import { Link } from "react-router-dom";
import React from "react";

export default function NotFound() {
  return (
    <div className="col-12 mt-4">
      <h1 className="display-3 text-center text-danger">404</h1>
      <h2 className="lead text-center text-danger">NOT FOUND</h2>
      <p className="text-center">The page you are looking for was not found</p>
      <p className="text-center">
        <Link to="/" className="btn btn-primary">
          Home
        </Link>
      </p>
    </div>
  );
}
