import React, { useEffect, useRef, useState } from "react";

const Dropdown = ({
  disabled,
  data,
  buttonText,
  position,
  header,
  handleDropdownSelection,
  scrollbar,
  multiSelection,
}) => {
  const filterDialog = useRef(null);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        filterDialog &&
        filterDialog.current &&
        filterDialog.current.parentNode &&
        !filterDialog.current.parentNode.contains(e.target)
      ) {
        setShowModal(false);
      }
    };

    if (showModal) {
      document.addEventListener("click", handleOutsideClick, false);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, false);
    };
  }, [showModal]);

  const handleDropdownButtonClick = (event) => {
    event.preventDefault();
    const show = showModal ? false : true;
    setShowModal(show);
  };

  const handleDropdownEvent = (item) => {
    handleDropdownSelection(item);
    setShowModal(false);
  };

  const updateStatus = (data, selectedFilter, isSelected) => {
    let dataWithSelectedFlag = [];

    dataWithSelectedFlag = data.map((item) => {
      return selectedFilter === item
        ? { ...item, isSelected: isSelected }
        : item;
    });
    handleDropdownSelection(dataWithSelectedFlag);
  };

  const handleFilterCheckboxSelection = (updatedItem, isSelected) => {
    updateStatus(data, updatedItem, isSelected);
  };

  return (
    <div className="me-2">
      <button
        disabled={disabled}
        className="button small"
        onClick={handleDropdownButtonClick}
      >
        {buttonText}
      </button>
      <div ref={filterDialog} className="drop-down">
        {showModal && (
          <div
            className={`rounded-extra-lg dialog ${
              scrollbar ? "scrollbar" : ""
            } ${position ? position : "right"} animate fadeIn fast`}
          >
            <div className="container">
              <h3 className="heading">{header}</h3>
              <ul>
                {data.map((item) => {
                  return multiSelection ? (
                    <div className="checkbox-container" key={item.value}>
                      <label className="checkbox-field">
                        <input
                          className="input"
                          type="checkbox"
                          name={item.value}
                          checked={item.isSelected ? true : false}
                          onChange={(e) =>
                            handleFilterCheckboxSelection(
                              item,
                              e.target.checked
                            )
                          }
                        />
                        <span className="primary">{item.value}</span>
                      </label>
                    </div>
                  ) : (
                    <li key={item.value}>
                      <button
                        type="button"
                        onClick={(e) => handleDropdownEvent(item)}
                      >
                        {item.description}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
