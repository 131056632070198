import React from "react";

export default function Unauthorize() {
  return (
    <div className="col-12 mt-4">
      <h2 className="lead text-center text-danger">UNAUTHORIZED</h2>
      <p className="text-center">
        You do not have permission to view this page, please sign in to access
        this page.
      </p>
      <p className="text-center"></p>
    </div>
  );
}
