import React, { useState } from "react";

import Error from "../common/ServerError";
import Modal from "../common/modal/modal";
import actionType from "../../constants/actionType";
import dataIntegrationApi from "../../api/dataIntegrationApi";
import { tokenRequest } from "../../authConfig";
import { useForm } from "react-hook-form";
import { useMsal } from "@azure/msal-react";

const RunDataExport = (props) => {
  const { instance, accounts } = useMsal();
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isConfirmInProgress, setIsConfirmInProgress] = useState(false);
  const [pipelineName, setPipelineName] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleDataIntegrationSubmit = (pipeline) => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    setIsConfirmInProgress(true);
    setResult(null);
    setErrorMessages([]);
    setError(null);
    setLoading(true);

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;

        (async () => {
          await dataIntegrationApi
            .runDataExportPipeline(accessToken, pipeline)
            .then((data) => {
              setError(null);
              setErrorMessages([]);
              setResult(data);
              setShowModal(false);
              reset();
            })
            .catch((e) => {
              console.log(e);
              if (e.response?.status === 400 && e.response?.data?.errors) {
                let errors = [];
                for (const [key, value] of Object.entries(
                  e.response.data.errors
                )) {
                  errors.push({ name: key, value: value });
                }
                setErrorMessages(errors);
              } else {
                setError(e);
              }
            })
            .finally(() => {
              setLoading(false);
              setIsConfirmInProgress(false);
              setShowModal(false);
            });
        })();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const onSubmit = async (data) => {
    setPipelineName(data.pipeline);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div className="row">
      {showModal ? (
        <Modal
          actionType={actionType.Confirm}
          name={pipelineName}
          setModalOpen={setShowModal}
          handleCancel={handleCancel}
          handleAction={handleDataIntegrationSubmit}
          actionInProgress={isConfirmInProgress ? isConfirmInProgress : false}
          actionCallbackValue={pipelineName}
        />
      ) : (
        ""
      )}

      <div className="col-12 my-3">
        <h4 className="">Data Integration</h4>
      </div>
      <div className="col-6">
        <div className="card shadow rounded-extra-lg">
          <div className="card-body">
            {error ? (
              <Error error={error} />
            ) : (
              <React.Fragment>
                <div className="my-3">
                  <h4>Run Data Export</h4>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label className="form-label">
                      This will trigger the selected Data Export pipeline
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Data export</label>
                    <select
                      name="pipeline"
                      className={`form-control ${
                        errors.pipeline ? "is-invalid" : ""
                      }`}
                      change=""
                      {...register("pipeline", {
                        required: {
                          value: true,
                          message: "The pipeline field is required.",
                        },
                      })}
                    >
                      {props.pipelines.map((option) => {
                        return (
                          <option value={option} key={option}>
                            {option}
                          </option>
                        );
                      })}
                    </select>
                    {errors.pipeline && (
                      <span className="invalid-feedback">
                        {errors.pipeline.message}
                      </span>
                    )}
                  </div>
                  <div className="form-row">
                    <div className="form-group col">
                      <button
                        type="submit"
                        className="button primary me-2"
                        disabled={loading}
                      >
                        Start
                        {loading ? (
                          <span className="disabled ms-2">
                            <span
                              className="spinner-grow spinner-grow-1 me-1"
                              role="status"
                            ></span>
                            <span
                              className="spinner-grow  spinner-grow-2 me-1"
                              role="status"
                            ></span>
                            <span
                              className="spinner-grow me-1"
                              role="status"
                            ></span>
                          </span>
                        ) : null}
                      </button>
                    </div>
                  </div>
                </form>

                {result ? <div className="text-success">{result}</div> : null}

                <div>
                  {errorMessages && errorMessages.length > 0 ? (
                    <div className="">
                      {errorMessages.map((e) => {
                        return (
                          <div key={e.name} className="mb-2 text-danger">
                            {e.value}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RunDataExport;
