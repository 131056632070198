const actionType = Object.freeze({
  Read: "Read",
  Edit: "Edit",
  Save: "Save",
  Delete: "Delete",
  Cancel: "Cancel",
  Warning: "Warning",
  Error: "Error",
  Help: "Help",
  Forbidden: "Forbidden ",
  Options: "Options",
  Confirm: "Confirm",
});

export default actionType;
