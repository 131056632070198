import React, { useState } from "react";

import Error from "../common/ServerError";
import Modal from "../common/modal/modal";
import actionType from "../../constants/actionType";
import searchIntegrationApi from "../../api/searchIntegrationApi";
import { tokenRequest } from "../../authConfig";
import { useForm } from "react-hook-form";
import { useMsal } from "@azure/msal-react";

const SearchDataRefresh = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { instance, accounts } = useMsal();
  const [result, setResult] = useState();
  const [error, setError] = useState();
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isConfirmInProgress, setIsConfirmInProgress] = useState(false);
  const [dataIntegrationLogId, setDataIntegrationLogId] = useState(null);

  const handleSearchDataRefreshSubmit = (dataIntegrationLogId) => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    setIsConfirmInProgress(true);
    setResult(null);
    setErrorMessages([]);
    setError(null);
    setLoading(true);

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await searchIntegrationApi
            .get(accessToken, dataIntegrationLogId)
            .then((data) => {
              setError(null);
              setErrorMessages([]);
              setShowModal(false);
              return data;
            })
            .catch((e) => {
              console.log(e);
              if (e.response?.status === 400 && e.response?.data?.errors) {
                let errors = [];
                for (const [key, value] of Object.entries(
                  e.response.data.errors
                )) {
                  errors.push({ name: key, value: value });
                }
                setErrorMessages(errors);
              } else {
                setError(e);
              }
            })
            .finally(() => {
              setLoading(false);
              setIsConfirmInProgress(false);
              setShowModal(false);
            });

          setResult(data);
          reset();
        })();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const onSubmit = async (data) => {
    setDataIntegrationLogId(data.dataIntegrationLogId);
    setShowModal(true);
  };

  const handleCancel = () => {
    setDataIntegrationLogId(null);
    setShowModal(false);
    setIsConfirmInProgress(false);
    setResult(null);
    setErrorMessages([]);
    setError(null);
    setLoading(false);
    reset();
  };

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="row">
      {showModal ? (
        <Modal
          actionType={actionType.Confirm}
          name={props.name}
          setModalOpen={setShowModal}
          handleCancel={handleCancel}
          handleAction={handleSearchDataRefreshSubmit}
          actionInProgress={isConfirmInProgress}
          actionCallbackValue={dataIntegrationLogId}
        />
      ) : (
        ""
      )}
      <div className="col-12 my-3">
        <h4 className="">Data Integration</h4>
      </div>
      <div className="col-6">
        <div className="card shadow rounded-extra-lg">
          <div className="card-body">
            <div className="my-3">
              <h4>Search Data Refresh</h4>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label className="form-label">Data Integration Log Id</label>
                <input
                  className={`form-control ${
                    errors.dataIntegrationLogId ? "is-invalid" : ""
                  }`}
                  name="dataIntegrationLogId"
                  {...register("dataIntegrationLogId", {
                    required: {
                      value: true,
                      message: "The data integration log id field is required.",
                    },
                  })}
                />
                {errors.dataIntegrationLogId && (
                  <span className="invalid-feedback">
                    {errors.dataIntegrationLogId.message}
                  </span>
                )}
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <button
                    type="submit"
                    className="button primary me-2"
                    disabled={loading}
                  >
                    Submit
                    {loading ? (
                      <span className="disabled ms-2">
                        <span
                          className="spinner-grow spinner-grow-1 me-1"
                          role="status"
                        ></span>
                        <span
                          className="spinner-grow  spinner-grow-2 me-1"
                          role="status"
                        ></span>
                        <span
                          className="spinner-grow me-1"
                          role="status"
                        ></span>
                      </span>
                    ) : null}
                  </button>
                </div>
              </div>
            </form>

            <div className="text-success">{result}</div>
            <div>
              {errorMessages && errorMessages.length > 0 ? (
                <div className="">
                  {errorMessages.map((e) => {
                    return (
                      <div key={e.name} className="mb-2 text-danger">
                        {e.value}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchDataRefresh;
