import { ReactComponent as CompleteIcon } from "../../components/icons/check_circle_icon.svg";
import { ReactComponent as DataExportIcon } from "../../components/icons/data_export_icon.svg";
import { ReactComponent as DataImportIcon } from "../../components/icons/data_import_icon.svg";
import { ReactComponent as DateTimeIcon } from "../../components/icons/calendar_time_icon.svg";
import { ReactComponent as ErrorIcon } from "../../components/icons/error_icon.svg";
import React from "react";
import { ReactComponent as WarningIcon } from "../../components/icons/warning_icon.svg";
import dataIntegrationName from "../../constants/dataIntegrationName";
import { getStatusClass } from "../common/utilities";
import status from "../../constants/status";
import useDataIntegration from "../data-integration/useDataIntegration";

const DataIntegrationCard = (props) => {
  const { dataIntegrationLog, errorWhileCheckingDataIntegration } =
    useDataIntegration(props.dataIntegrationName);

  const getDataIntegrationIcon = (name) => {
    switch (name) {
      case dataIntegrationName.DataImport:
        return <DataImportIcon />;
      case dataIntegrationName.DataExport:
        return <DataExportIcon />;
      default:
        break;
    }
  };

  const getStatusIcon = (integrationStatus) => {
    switch (integrationStatus) {
      case status.InProgress:
        return <WarningIcon />;
      case status.Completed:
        return <CompleteIcon />;
      case status.Failed:
        return <ErrorIcon />;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {dataIntegrationLog && !errorWhileCheckingDataIntegration ? (
        <div className="col-12 col-md-6 col-lg-4 col-xll-4 mt-3">
          <div className="card shadow rounded-extra-lg">
            <div className="icon-card mt-3">
              <div className="icon-card-content layered-shadow rounded-extra-lg">
                {getDataIntegrationIcon(props.dataIntegrationName)}
              </div>
            </div>

            <div className="card-body">
              <h5 className="mb-0">{dataIntegrationLog.name}</h5>
              <div
                className={`icon text-${getStatusClass(
                  dataIntegrationLog.status
                )} py-2 svg-margin-n2`}
              >
                {getStatusIcon(dataIntegrationLog.status)}{" "}
                {dataIntegrationLog.status}
              </div>
              <div className="icon font-weight-medium text-grey-500 extra-small-icon">
                <DateTimeIcon /> {dataIntegrationLog.formattedModified}
              </div>
            </div>
          </div>
        </div>
      ) : errorWhileCheckingDataIntegration ? (
        <div className="col-12 col-md-6 col-lg-4 col-xll-4 mt-3">
          <div className="card shadow rounded-extra-lg">
            <div className="icon-card mt-3">
              <div className="icon-card-content layered-shadow rounded-extra-lg">
                {getDataIntegrationIcon(props.dataIntegrationName)}
              </div>
            </div>

            <div className="card-body">
              <h5 className="mb-0">{props.dataIntegrationName}</h5>
              <div className="mt-2 font-weight-medium text-danger">
                {`An unexpected error occurred while checking for ${props.dataIntegrationName} status, please try again later.`}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DataIntegrationCard;