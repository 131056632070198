import role from "../../constants/role";

const authorizationHelper = () => {
  const isDataAdmin = (roles) => {
    if (!roles) {
      return false;
    }

    return roles.includes(role.SuperAdmin) || roles.includes(role.DataAdmin);
  };

  const isAuthorized = (allClaims, claimToCheck) => {
    if (!allClaims) {
      return false;
    }

    if (isDataAdmin(allClaims.roles)) {
      return true;
    }

    return allClaims.claims && allClaims.claims.includes(claimToCheck);
  };

  return [isDataAdmin, isAuthorized];
};

export default authorizationHelper;
