import RunDataExport from "../data-integration/RunDataExport";
import RunDataIntegration from "../data-integration/RunDataIntegration";
import dataIntegrationName from "../../constants/dataIntegrationName";

const DataExport = (props) => {
  return (
    <div className="row">
      {props.pipelines && props.pipelines.length > 0 ? (
        <RunDataExport pipelines={props.pipelines} />
      ) : (
        <RunDataIntegration
          key={dataIntegrationName.DataExport}
          name={dataIntegrationName.DataExport}
        />
      )}
    </div>
  );
};

export default DataExport;
