const claims = Object.freeze({
  Configuration: "Configuration",
  SearchRead: "SearchRead",
  ForecastOverrideWrite: "ForecastOverrideWrite",
  ForecastOverrideFileUploadWrite: "ForecastOverrideFileUploadWrite",
  ForecastOverrideLogs: "ForecastOverrideLogs",
  ProjectsRead: "ProjectsRead",
  ProjectsWrite: "ProjectsWrite",
  ProjectsDelete: "ProjectsDelete",
  ProjectFileUploadWrite: "ProjectFileUploadWrite",
  RunDataExportPipeline: "RunDataExportPipeline",
  RunDataImportPipeline: "RunDataImportPipeline",
  DataIntegrationWrite: "DataIntegrationWrite",
  ForecastOverrideAuditLogs: "ForecastOverrideAuditLogs",
  DataExportPipelines: "DataExportPipelines",
});

export default claims;
