import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { useState } from "react";

import { formatNumber } from "../common/formatHelper";

const DbForecastChart = (props) => {
  const [legendDisplay, setLegendDisplay] = useState({});
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="chart-tooltip">
          <div className="chart-label">{label}</div>
          {payload.map((p) => {
            return (
              <div className="chart-item-container" key={p.name}>
                <div className="pill" style={{ backgroundColor: p.fill }}></div>
                <div className="chart-item">{`${p.name} : ${formatNumber(
                  p.value,
                  props.config.decimalPlaces
                )} `}</div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const handleLegendOnClick = (o) => {
    const { dataKey } = o;
    setLegendDisplay((op) => ({ ...op, [dataKey]: !op[dataKey] }));
  };

  const labelRender = (args) => {
    var value = parseInt(args);
    if (value >= 1000 && value < 999999) {
      var kText = (value / 1000).toFixed(2);
      return kText + "K";
    } else if (value > 999999) {
      var text = (value / 1000000).toFixed(2);
      return text + "M";
    }

    return args;
  };

  return (
    <div className="col-12">
      <div className="card shadow-sm rounded-extra-lg pt-3">
        <div
          className="card-body"
          key="forecastchart"
          style={{ width: "100%", height: 300 }}
        >
          <ResponsiveContainer>
            <ComposedChart data={props.data}>
              <CartesianGrid stroke="#dfe6ee" />
              <XAxis dataKey="label" stroke="#b0bac5" />
              <YAxis tickFormatter={labelRender} stroke="#b0bac5" />
              <Tooltip content={<CustomTooltip />} />
              <Legend onClick={handleLegendOnClick} />
              {props.config.attributeConfigurations
                .filter(
                  (c) => c.isVisible && c.chartType.toLowerCase() !== "none"
                )
                .map((c) =>
                  c.chartType === "Line" ? (
                    <Line
                      type="linear"
                      dataKey={c.attributeName.toLowerCase()}
                      stroke={c.chartColor}
                      fill={c.chartColor}
                      name={c.displayName}
                      hide={legendDisplay[c.attributeName.toLowerCase()]}
                      key={c.attributeName.toLowerCase()}
                    />
                  ) : (
                    <Bar
                      dataKey={c.attributeName.toLowerCase()}
                      barSize={25}
                      fill={c.chartColor}
                      name={c.displayName}
                      hide={legendDisplay[c.attributeName.toLowerCase()]}
                      key={c.attributeName.toLowerCase()}
                    />
                  )
                )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default DbForecastChart;
