import React, { useEffect, useState } from "react";

import Dropdown from "../common/dropdown/dropdown";
import Error from "../common/ServerError";
import ForecastAuditSearchListing from "./ForecastAuditSearchListing";
import Modal from "../common/modal/modal";
import Search from "../search/Search";
import Spinner from "../common/Spinner";
import actionType from "../../constants/actionType";
import forecastConfigApi from "../../api/forecastConfigApi";
import { tokenRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

const ForecastAuditSearch = () => {
  const { instance, accounts } = useMsal();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [configuration, setConfiguration] = useState();

  const [searchDataForProjects, setSearchDataForProjects] = useState(null);

  const [currentFilterAttributeName, setCurrentFilterAttributeName] =
    useState(null);

  const [columns, setColumns] = useState([]);

  const [showAuthorizationErrorModel, setShowAuthorizationErrorModel] =
    useState(false);

  const [authorizationError, setAuthorizationError] = useState(null);

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    setLoading(true);

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await forecastConfigApi
            .auditConfiguration(accessToken)
            .then((data) => {
              setError(null);
              if (data) {
                setConfiguration(data);
              }
            })
            .catch((error) => {
              setError(error);
              console.log(error);
            })
            .finally(() => setLoading(false));
        })();
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      });
  }, [instance, accounts]);

  useEffect(() => {
    if (!configuration) {
      return;
    }

    var columns = configuration.searchListingConfigurations
      .filter((f) => f.isCustomizable === true)
      .map((f) => {
        return {
          value: f.header,
          description: f.header,
          isSelected: false,
        };
      });

    setColumns(columns);
  }, [configuration]);

  const handleShowAuthorizationErrorModel = (e) => {
    setShowAuthorizationErrorModel((showAuthorizationErrorModel) => {
      return !showAuthorizationErrorModel;
    });
    setAuthorizationError(e);
  };

  const handleColumnSelection = (updatedData) => {
    setColumns(updatedData);
  };

  if (error) {
    return <Error error={error} />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      {showAuthorizationErrorModel ? (
        <Modal
          actionType={actionType.Forbidden}
          header={"Forbidden"}
          subHeader={authorizationError?.response?.data?.message}
          handleCancel={handleShowAuthorizationErrorModel}
          setModalOpen={setShowAuthorizationErrorModel}
          showAuthorizationErrorModel={showAuthorizationErrorModel}
        />
      ) : null}

      <React.Fragment>
        <Search
          showUom={false}
          showForecast={false}
          setSearchData={setSearchDataForProjects}
          isWriteOperation={false}
          searchProjects={false}
          configurationComponent={
            <Dropdown
              disabled={false}
              data={columns}
              buttonText={"Customize Columns"}
              position={"left"}
              header={"Columns"}
              handleDropdownSelection={handleColumnSelection}
              scrollbar={true}
              multiSelection={true}
            />
          }
          setCurrentFilterAttributeName={setCurrentFilterAttributeName}
          parent={"forecastaudit"}
        />
        {configuration ? (
          <React.Fragment>
            <ForecastAuditSearchListing
              configuration={configuration}
              selectedSearchData={searchDataForProjects}
              columns={columns}
              currentFilterAttributeName={currentFilterAttributeName}
              handleShowAuthorizationErrorModel={
                handleShowAuthorizationErrorModel
              }
              setAuthorizationError={setAuthorizationError}
            />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    </React.Fragment>
  );
};

export default ForecastAuditSearch;
