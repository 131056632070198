import locale from "../../constants/locale";

const dateHelper = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function getYearAndMonthRange(startDateInString, endDateInString) {
    const startDate = new Date(startDateInString);
    const endDate = new Date(endDateInString);

    for (
      var range = [], date = startDate;
      date <= endDate;
      date = new Date(date.getFullYear(), date.getMonth() + 1, 1)
    ) {
      const value = {
        label: `${months[date.getMonth()]} ${date.getFullYear()}`,
        formattedTimeBucket: date.toLocaleDateString(
          process.env.REACT_APP_LOCALE
        ),
        timeBucket: new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1)),
      };
      range.push(value);
    }

    return range;
  }

  const datePlaceholder = () => {
    return process.env.REACT_APP_LOCALE === locale.EN_US
      ? "MM/dd/yyyy"
      : "dd/MM/yyyy";
  };

  const getDateInUTC = (dateInStringFormat) => {
    if (!dateInStringFormat) {
       throw new Error("Date can not be empty or undefined");
    }
    const dateParts = dateInStringFormat.split("/");
    let monthIndex = process.env.REACT_APP_LOCALE === locale.EN_US ? 0 : 1;
    return new Date(Date.UTC(dateParts[2], dateParts[monthIndex] - 1, 1));
  };

  return [getYearAndMonthRange, datePlaceholder, getDateInUTC];
};

export default dateHelper;
