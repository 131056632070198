import React, { useEffect, useState } from "react";

import { ReactComponent as ArrowIcon } from "../../components/icons/arrow_icon.svg";
import OverFlowToolTip from "../common/OverFlowToolTip";
import ProjectTable from "./ProjectTable";
import actionType from "../../constants/actionType";
import claims from "../../constants/claims";
import distribution from "../../constants/distribution";
import entityName from "../../constants/entityName";
import { formatNumber } from "../common/formatHelper";
import { isTrue } from "./../common/utilities";
import overrideOptions from "../../constants/overrideOptions";
import { parseCliboardData } from "../common/excelUtilities";
import { useForm } from "react-hook-form";
import valueType from "../../constants/valueType";

function DbForecastTable({
  data,
  config,
  handleSave,
  currentAction,
  saveOnlyChangedTimeBuckets,
  forecastRequest,
  anyOptionsSelected,
  isUserAuthorized,
  setEnableOptions,
  selectedOptions,
}) {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [selectedForecastData, setSelectedForecastData] = useState([]);
  const [isAnyRowInEditMode, setIsAnyRowInEditMode] = useState(true);
  const [selectedRow, setSelectedRow] = useState({});
  const [showProjectTable, setShowProjectTable] = useState([]);
  const [selectedProjectRow, setSelectedProjectRow] = useState();
  const [
    selectedOptionsOverrideTimeBuckets,
    setSelectedOptionsOverrideTimeBuckets,
  ] = useState([]);
  const [isCtrlClicked, setIsCtrlClicked] = useState(false);

  const setSelectedForecast = (item, c, copiedValue) => {
    setSelectedForecastData((prevData) => {
      const currentCell = prevData.find(
        (f) => f.key === item.label + c.attributeName
      );
      let isHighlighted = false;
      if (currentCell == null) {
        // check if any other item cell selected for this time bucket
        const anyExist = selectedForecastData.find(
          (f) => f.label === item.label
        );
        if (anyExist) {
          isHighlighted = true;
        } else if (
          selectedRow &&
          selectedRow.isSelected &&
          selectedRow.attributeName === c.attributeName.toLowerCase()
        ) {
          isHighlighted = false;
          return [
            ...prevData,
            {
              key: item.label + c.attributeName,
              label: item.label,
              attributeName: c.attributeName.toLowerCase(),
              isHighlighted,
            },
          ];
        } else {
          isHighlighted = true;
        }
      } else {
        isHighlighted = !currentCell.isHighlighted;
      }

      if (isHighlighted) {
        return [
          ...prevData.filter((d) => d.label !== item.label),
          {
            key: item.label + c.attributeName,
            label: item.label,
            attributeName: c.attributeName.toLowerCase(),
            isHighlighted,
            forecastdate: item.forecastdate,
            [c.attributeName.toLowerCase()]: copiedValue
              ? copiedValue
              : item[c.attributeName.toLowerCase()],
          },
        ];
      } else {
        return prevData != null && prevData.length > 0
          ? [
              ...prevData.map((d) => {
                return d.key === item.label + c.attributeName
                  ? { ...d, isHighlighted }
                  : d;
              }),
            ]
          : [
              {
                key: item.label + c.attributeName,
                label: item.label,
                attributeName: c.attributeName.toLowerCase(),
                isHighlighted,
                forecastdate: item.forecastdate,
                [c.attributeName.toLowerCase()]: copiedValue
                  ? copiedValue
                  : item[c.attributeName.toLowerCase()],
              },
            ];
      }
    });
  };

  const isRowClickable = (c) => {
    const result =
      (!c.isEditable &&
        isAnyRowInEditMode &&
        c.parent !== "Projects" &&
        !selectedOptions) ||
      (c.isEditable &&
        isAnyRowInEditMode &&
        c.attributeName === actions.attributeName &&
        c.attributeName.toLowerCase() === "commercialoverride" &&
        selectedOptions);

    return result;
  };

  const handleRowSelection = (e, c) => {
    if (!isRowClickable(c)) {
      return;
    }

    let isSelected =
      selectedRow != null &&
      selectedRow.isSelected &&
      selectedRow.attributeName === c.attributeName.toLowerCase();

    setSelectedRow({
      isSelected: !isSelected,
      attributeName: c.attributeName.toLowerCase(),
    });

    setSelectedForecastData([]);
    setSelectedOptionsOverrideTimeBuckets([]);
    reset();

    if (!isSelected && selectedOptions && c.isEditable) {
      handleOptionsOverrideRowSelection(e, c);
      return;
    }
  };

  const handleMouseDown = (e, c, item) => {
    if (isAnyRowInEditMode && !selectedOptions) {
      setIsMouseDown(true);
      setSelectedForecast(item, c);
      reset();
      return false;
    }
  };

  const handleMouseOver = (e, item, c) => {
    if (isMouseDown && isAnyRowInEditMode && !selectedOptions) {
      setSelectedForecast(item, c);
    }
  };

  const handleMouseUp = (e) => {
    setIsMouseDown(false);
  };

  const isSelected = (item, c) => {
    const matchingItem = selectedForecastData.find(
      (f) => f.key === item.label + c.attributeName
    );

    if (matchingItem) {
      return matchingItem.isHighlighted;
    } else if (matchingItem === undefined) {
      // check if any other item cell selected for this time bucket
      const anyExist = selectedForecastData.find((f) => f.label === item.label);
      if (anyExist) {
        return false;
      }
      return (
        selectedRow &&
        selectedRow.isSelected &&
        selectedRow.attributeName === c.attributeName.toLowerCase()
      );
    }

    return false;
  };

  const getValue = (item, c) => {
    const timeBucket = selectedForecastData.filter(
      (f) => f.label === item.label
    );

    let key;

    if (timeBucket.length === 0) {
      key =
        selectedRow && selectedRow.isSelected
          ? selectedRow.attributeName
          : c.attributeName.toLowerCase();
    } else {
      if (timeBucket.length > 0) {
        const highlightedTimeBucket = timeBucket.find((f) => f.isHighlighted);
        if (highlightedTimeBucket && highlightedTimeBucket.isHighlighted) {
          return highlightedTimeBucket[
            highlightedTimeBucket.attributeName.toLowerCase()
          ];
        } else {
          key = highlightedTimeBucket
            ? highlightedTimeBucket.attributeName.toLowerCase()
            : c.attributeName.toLowerCase();
        }
      } else {
        key = c.attributeName.toLowerCase();
      }
    }
    return item[key];
  };

  const handleOnPaste = (item, c, e, index) => {
    reset();
    return parseCliboardData(e);
  };

  const handleOnInput = (item, c, e, index) => {
    setSelectedForecast(item, c, e.target.value);
  };

  const handleOnInputKeyPress = (e) => {
    setIsCtrlClicked(e.ctrlKey ? true : false);
  };

  const [actions, setActions] = useState(
    currentAction?.attributeConfiguration != null
      ? {
          [currentAction.attributeConfiguration.attributeName]:
            currentAction.action,
          attributeName: currentAction.attributeConfiguration.attributeName,
          areaCode: currentAction.areaCode,
          item: currentAction.item,
          entityName: currentAction.attributeConfiguration.parent,
        }
      : {}
  );

  const {
    register: forecastTimeHorizonRegister,
    formState: { errors: forecastTimeHorizonErrors },
    handleSubmit: forecastTimeHorizonHandleSubmit,
    reset,
    setValue,
  } = useForm();

  const onForecastSaveHandler = async (dataToBeSaved) => {
    let timeHorizonValuesToBeCreated = [];

    for (const [key, value] of Object.entries(dataToBeSaved)) {
      const originalValue = data.find((f) => f.forecastdate === key)[
        actions.attributeName.toLowerCase()
      ];

      let timeBucket = key;

      if (actions.entityName === entityName.History) {
        timeBucket = new Date(key);
        timeBucket = new Date(
          Date.UTC(
            timeBucket.getFullYear() - 1,
            timeBucket.getMonth(),
            timeBucket.getDate()
          )
        );
      }

      // eslint-disable-next-line eqeqeq
      if (!saveOnlyChangedTimeBuckets || originalValue != value) {
        timeHorizonValuesToBeCreated.push({
          timeBucket: timeBucket,
          value: value,
        });
      } else if (anyOptionsSelected()) {
        const anySelectedForecastData = selectedOptionsOverrideTimeBuckets.find(
          (f) => f.isHighlighted && f.forecastdate === key
        );
        if (
          anySelectedForecastData ||
          (selectedRow && selectedRow.isSelected)
        ) {
          timeHorizonValuesToBeCreated.push({
            timeBucket: timeBucket,
            value: value,
          });
        }
      }
    }

    handleSave(
      timeHorizonValuesToBeCreated,
      actions.attributeName,
      actions.entityName
    );
  };

  const handleShowProjects = (c) => {
    if (!c) {
      return;
    }

    setSelectedProjectRow(c);

    setShowProjectTable((previousSelections) => {
      const match = previousSelections.find(
        (p) => p.attributeName === c.attributeName
      )?.value;
      return [
        ...previousSelections.filter(
          (p) => p.attributeName !== c.attributeName
        ),
        {
          attributeName: c.attributeName,
          value: !match,
        },
      ];
    });
  };

  const isProjectRowExpanded = (c) => {
    return showProjectTable.find((p) => p.attributeName === c.attributeName)
      ?.value;
  };

  const getOptionsOverrideValue = (selectedTimeBucket, overrideTimeBuckets) => {
    let result = 0;

    if (!selectedOptions) {
      return selectedTimeBucket.actualValue;
    }

    if (selectedOptions.value === overrideOptions.RemoveOverrides) {
      return data.find((d) => d.label === selectedTimeBucket.label)
        .statisticalforecast;
    }

    if (!selectedOptions.optionValue) {
      return selectedTimeBucket.actualValue;
    }

    const optionValue = parseFloat(selectedOptions.optionValue);
    const actualTimeBucketValue = parseFloat(selectedTimeBucket.actualValue);

      let calculatedOptionValue = optionValue;

    switch (selectedOptions.distribution) {
      case distribution.Same:
        calculatedOptionValue = optionValue;
        break;

      case distribution.EvenlySpread:
        const numberOfTimeBuckets = overrideTimeBuckets.filter(
          (d) => d.isHighlighted
        ).length;

        const evenlyValue = parseFloat(
          formatNumber(
            optionValue / (numberOfTimeBuckets > 0 ? numberOfTimeBuckets : 1),
            config.decimalPlaces
          )
        );

        calculatedOptionValue = evenlyValue;
        break;

      case distribution.ProportionallyFromForecast:
        const totalValue = overrideTimeBuckets
          .filter((d) => d.isHighlighted)
          .reduce(function (a, d) {
            return a + parseFloat(d.actualValue);
          }, 0);

        const proportionedValue = parseFloat(
          formatNumber(
            (optionValue * actualTimeBucketValue) / totalValue,
            config.decimalPlaces
          )
        );
        calculatedOptionValue = proportionedValue;
        break;

      default:
        break;
    }

    switch (selectedOptions.valueType) {
      case valueType.Absolute:
        result = calculatedOptionValue;
        break;
      case valueType.Increment:
        result = actualTimeBucketValue + calculatedOptionValue;
        break;
      case valueType.Decrement:
        result = actualTimeBucketValue - calculatedOptionValue;
        break;
      case valueType.PercentageIncrease:
        result =
          actualTimeBucketValue +
          parseFloat(
            formatNumber(
              (actualTimeBucketValue * calculatedOptionValue) / 100,
              config.decimalPlaces
            )
          );
        break;
      case valueType.PercentageDecrease:
        result =
          actualTimeBucketValue -
          parseFloat(
            formatNumber(
              (actualTimeBucketValue * calculatedOptionValue) / 100,
              config.decimalPlaces
            )
          );
        break;
      default:
        break;
    }

    return parseFloat(result);
  };

  const handleOptionsOverrideTimeBuckets = (item, attributeConfiguration) => {
    setSelectedOptionsOverrideTimeBuckets((prevData) => {
      const currentCell = prevData.find(
        (f) => f.key === item.label + attributeConfiguration.attributeName
      );
      let isHighlighted = false;
      if (currentCell == null) {
        isHighlighted = true;
      } else {
        isHighlighted = !currentCell.isHighlighted;
      }

      if (isHighlighted) {
        const selectedItemObject = {
          key: item.label + attributeConfiguration.attributeName,
          label: item.label,
          attributeName: attributeConfiguration.attributeName.toLowerCase(),
          isHighlighted,
          forecastdate: item.forecastdate,
          [attributeConfiguration.attributeName.toLowerCase()]:
            item[attributeConfiguration.attributeName.toLowerCase()],
          actualValue: item[attributeConfiguration.attributeName.toLowerCase()],
        };

        let list = [
          ...prevData
            .filter((d) => d.label !== item.label)
            .map((d) => {
              return {
                ...d,
              };
            }),
          {
            ...selectedItemObject,
          },
        ];

        list = [
          ...list.map((d) => {
            const value = d.isHighlighted
              ? getOptionsOverrideValue(d, list)
              : d[d.attributeName];
            setValue(d.forecastdate, value);
            return {
              ...d,
              [attributeConfiguration.attributeName.toLowerCase()]: value,
            };
          }),
        ];

        return list;
      } else {
        if (prevData != null && prevData.length > 0) {
          let list = [
            ...prevData.map((d) => {
              return d.key === item.label + attributeConfiguration.attributeName
                ? {
                    ...d,
                    [attributeConfiguration.attributeName.toLowerCase()]:
                      item[attributeConfiguration.attributeName.toLowerCase()],
                    isHighlighted,
                  }
                : d;
            }),
          ];

          list = [
            ...list.map((d) => {
              const value = d.isHighlighted
                ? getOptionsOverrideValue(d, list)
                : d[d.attributeName];

              setValue(d.forecastdate, value);
              return {
                ...d,
                [attributeConfiguration.attributeName.toLowerCase()]: value,
              };
            }),
          ];

          return list;
        } else {
          const list = [
            {
              key: item.label + attributeConfiguration.attributeName,
              label: item.label,
              attributeName: attributeConfiguration.attributeName.toLowerCase(),
              isHighlighted,
              forecastdate: item.forecastdate,
              [attributeConfiguration.attributeName.toLowerCase()]:
                item[attributeConfiguration.attributeName.toLowerCase()],
              actualValue:
                item[attributeConfiguration.attributeName.toLowerCase()],
            },
          ];

          list.map((d) => {
            setValue(d.forecastdate, d[d.attributeName]);
            return d;
          });

          return list;
        }
      }
    });
  };

  const handleOptionsOverrideRowSelection = (e, attributeConfiguration) => {
    if (!isRowClickable(attributeConfiguration)) {
      return;
    }

    setSelectedForecastData([]);
    setSelectedOptionsOverrideTimeBuckets([]);

    if (selectedOptions) {
      reset();
      data.map((item) => {
        // reset();
        handleOptionsOverrideTimeBuckets(item, attributeConfiguration);
        return item;
      });
    }
  };

  const handleOptionsOverrideOnClick = (item, attributeConfiguration, e) => {
    if (e.ctrlKey) {
      //reset any other selection if user selects editable fields for advanced options
      setSelectedForecastData([]);
      setSelectedRow({});
      reset();

      handleOptionsOverrideTimeBuckets(item, attributeConfiguration);
    }
  };

  const isOverrideCellClickable = (c) => {
    const result =
      c.isEditable &&
      isAnyRowInEditMode &&
      c.attributeName === actions.attributeName &&
      c.attributeName.toLowerCase() === "commercialoverride" &&
      (selectedOptions || isCtrlClicked);

    return result;
  };

  const isOverrideCellSelected = (item, c) => {
    const matchingItem = selectedOptionsOverrideTimeBuckets.find(
      (f) => f.key === item.label + c.attributeName
    );

    if (matchingItem) {
      return matchingItem.isHighlighted;
    } else if (matchingItem === undefined) {
      return false;
    }
  };

  useEffect(() => {
    if (!selectedOptions) {
      setSelectedOptionsOverrideTimeBuckets([]);
      reset();
      return;
    }

    setSelectedRow({});
    setSelectedForecastData([]);
    setSelectedOptionsOverrideTimeBuckets((prevData) => {
      const list = [
        ...prevData.map((item) => {
          return item.isHighlighted
            ? {
                ...item,
                [item.attributeName]: getOptionsOverrideValue(item, prevData),
              }
            : item;
        }),
      ];

      list.map((d) => {
        setValue(d.forecastdate, d[d.attributeName]);
        return d;
      });

      return list;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions, reset, setValue]);

  const getActionTemplate = (attributeConfiguration) => {
    const selectedActionType =
      actions &&
      actions[attributeConfiguration.attributeName] === actionType.Edit &&
      !actions.item &&
      !actions.areaCode
        ? actionType.Edit
        : actionType.Cancel;

    switch (selectedActionType) {
      case actionType.Edit:
        return (
          <div className="flex">
            <button type="submit" className="link primary small bold underline">
              <span>Save</span>
            </button>
            <button
              type="button"
              className="link small bold underline clear"
              onClick={(e) =>
                handleAction(e, attributeConfiguration, actionType.Cancel)
              }
            >
              <span>Cancel</span>
            </button>
          </div>
        );

      default:
        return (
          <div className="flex">
            <button
              type="button"
              className="link small bold underline"
              onClick={(e) =>
                handleAction(e, attributeConfiguration, actionType.Edit)
              }
            >
              <span>Edit</span>
            </button>
            <button
              type="button"
              className="link small bold underline clear"
              disabled={true}
            >
              <span>Cancel</span>
            </button>
          </div>
        );
    }
  };

  const handleAction = (e, attributeConfiguration, action) => {
    e.preventDefault();

    reset();
    setSelectedForecastData([]);
    setIsAnyRowInEditMode(false);
    setSelectedRow({});
    setSelectedOptionsOverrideTimeBuckets([]);

    setEnableOptions(attributeConfiguration.parent === entityName.Forecast);

    if (action === actionType.Edit) {
      setIsAnyRowInEditMode(true);
    }

    setActions((prevActions) => {
      return {
        [attributeConfiguration.attributeName]: action,
        attributeName: attributeConfiguration.attributeName,
        item: null,
        areaCode: null,
        entityName: attributeConfiguration.parent,
      };
    });
  };

  const getForecastTemplate = () => {
    const dateLables = () =>
      data.map((item) => {
        return (
          <th
            scope="col"
            style={{ width: "7rem" }}
            className="text-end"
            key={item.label}
          >
            {item.label}
          </th>
        );
      });

    const rows = () =>
      config.attributeConfigurations.map((c) => {
        return (
          <React.Fragment
            key={"forecast-rows" + c.parent + c.attributeName + c.displayName}
          >
            <tr key={c.attributeName}>
              <th scope="col" className="name">
                <div className="flex">
                  <div
                    className={
                      isTrue(config.isForecastTotalEditable) ? "actions" : ""
                    }
                  >
                    {c.isEditable &&
                    isUserAuthorized(claims.ForecastOverrideWrite) &&
                    isTrue(config.isForecastTotalEditable) ? (
                      getActionTemplate(c)
                    ) : (
                      <span> </span>
                    )}
                    {c.parent === "Projects" ? (
                      <button
                        type="button"
                        className="link float-end"
                        onClick={() => handleShowProjects(c)}
                      >
                        <span
                          className={`arrow-icon ${
                            isProjectRowExpanded(c) === true
                              ? "expanded"
                              : "collapsed"
                          }`}
                        >
                          <ArrowIcon />
                        </span>
                      </button>
                    ) : (
                      <span> </span>
                    )}
                  </div>
                  <div
                    className={`${isRowClickable(c) ? "cursor-pointer" : ""}`}
                    style={{ width: "11rem" }}
                    onClick={(e) => handleRowSelection(e, c)}
                  >
                    <OverFlowToolTip>{c.displayName}</OverFlowToolTip>
                  </div>
                </div>
              </th>
              {data.map((item, index) => {
                return c.isEditable &&
                  actions &&
                  actions[c.attributeName] === actionType.Edit &&
                  !actions.item &&
                  !actions.areaCode ? (
                  <td
                    className={`editable text-end ${
                      isOverrideCellClickable(c) ? "cursor-pointer" : ""
                    } text-end ${
                      isOverrideCellSelected(item, c) ? "highlighted" : ""
                    }`}
                    key={index + item.label + c.displayName + c.attributeName}
                  >
                    <div>
                      <input
                        className={`${
                          isOverrideCellClickable(c) ? "cursor-pointer" : ""
                        } text-end form-control ${
                          forecastTimeHorizonErrors?.[item.forecastdate]
                            ? "is-invalid"
                            : "input-focus"
                        } ${
                          c.attributeName.toLowerCase() ===
                            "commercialoverride" && item.isOverrideInProcess
                            ? "danger text-danger"
                            : ""
                        }`}
                        name={item.forecastdate}
                        type="number"
                        {...forecastTimeHorizonRegister(
                          `${item.forecastdate}`,
                          {
                            required: {
                              value: true,
                              message: `The ${item.label} field is required.`,
                            },
                          }
                        )}
                        onInput={(e) => {
                          handleOnInput(item, c, e, index);
                        }}
                        onPaste={(e) => {
                          handleOnPaste(item, c, e, index);
                        }}
                        onClick={(e) => {
                          handleOptionsOverrideOnClick(item, c, e);
                        }}
                        onKeyDown={(e) => handleOnInputKeyPress(e)}
                        onKeyUp={(e) => handleOnInputKeyPress(e)}
                        step="any"
                        defaultValue={getValue(item, c)}
                      />
                      {forecastTimeHorizonErrors?.[item.forecastdate] && (
                        <span className="invalid-feedback">
                          {
                            forecastTimeHorizonErrors?.[item.forecastdate]
                              ?.message
                          }
                        </span>
                      )}
                    </div>
                  </td>
                ) : (
                  <td
                    className={`${
                      isAnyRowInEditMode && !selectedOptions
                        ? "cursor-pointer"
                        : ""
                    } text-end ${isSelected(item, c) ? "highlighted" : ""}`}
                    key={index + item.label + c.displayName + c.attributeName}
                  >
                    <div
                      onMouseDown={(e) => handleMouseDown(e, c, item)}
                      onMouseOver={(e) => handleMouseOver(e, item, c)}
                      onMouseUp={(e) => handleMouseUp(e)}
                      style={{ width: "7rem" }}
                      className={`${
                        c.attributeName.toLowerCase() ===
                          "commercialoverride" && item.isOverrideInProcess
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      <OverFlowToolTip>
                        {formatNumber(
                          item[c.attributeName.toLowerCase()],
                          config.decimalPlaces
                        )}
                      </OverFlowToolTip>
                    </div>
                  </td>
                );
              })}
            </tr>
            {c.parent === "Projects" && isProjectRowExpanded(c) === true ? (
              <tr>
                <td colSpan="18" className="p-0">
                  <ProjectTable
                    forecastRequest={forecastRequest}
                    config={config}
                    selectedProjectRow={selectedProjectRow}
                  />
                </td>
              </tr>
            ) : null}
          </React.Fragment>
        );
      });

    return (
      <React.Fragment>
        <table className="forecast table table-lg table-striped-custom table-header-border">
          <thead>
            <tr>
              <th scope="col">
                <div className="flex">
                  <div
                    className={
                      isTrue(config.isForecastTotalEditable) ? "actions" : ""
                    }
                  ></div>
                  <div className="text-start">
                    {config.attributeDisplayNameHeader != null
                      ? config.attributeDisplayNameHeader
                      : "Name"}
                  </div>
                </div>
              </th>
              {dateLables()}
            </tr>
          </thead>
          <tbody>{rows()}</tbody>
        </table>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <form
        onSubmit={forecastTimeHorizonHandleSubmit(onForecastSaveHandler)}
        key="create-project-time-horizon-parameters"
      >
        {getForecastTemplate()}
      </form>
    </React.Fragment>
  );
}

export default DbForecastTable;
