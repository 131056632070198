import DataIntegrationCard from "../../data-integration/DataIntegrationCard";
import React from "react";
import dataIntegrationName from "../../../constants/dataIntegrationName";

const Home = () => {
  return (
    <React.Fragment>
      <div className="row pt-3 ps-2">
        <div className="col-12">
          <h4>Dashboard</h4>
          <div className="row">
            <DataIntegrationCard
              dataIntegrationName={dataIntegrationName.DataImport}
            />
            <DataIntegrationCard
              dataIntegrationName={dataIntegrationName.DataExport}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
