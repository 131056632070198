import React, { useEffect, useState } from "react";
import { addMonths, isTrue, subtractMonths } from "./../common/utilities";

import Error from "../common/ServerError";
import ForecastChart from "./ForecastChart";
import ForecastTable from "./ForecastTable";
import ItemAreaForecast from "./ItemAreaForecast";
import Spinner from "../common/Spinner";
import dateHelper from "../common/dateHelper";
import facetApi from "../../api/facetApi";
import forecastViewType from "../../constants/forecastViewType";
import { tokenRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

const Forecast = ({
  config,
  selectedSearchData,
  handleEdit,
  currentView,
  checkDataEditLock,
  setAuthorizationError,
  isUserAuthorized,
  currentAction,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [results, setResults] = useState({});
  const [request, setRequest] = useState(null);
  const { instance, accounts } = useMsal();
  const [, , getDateInUTC] = dateHelper();

  useEffect(() => {
    if (!config || !config?.fields?.length > 0) {
      return;
    }

    let startDateInUTC = null;
    let endDateInUTC = null;
    if (
      config.forecastTotalFromDate != null &&
      config.forecastTotalToDate != null
    ) {
      startDateInUTC = getDateInUTC(config.forecastTotalFromDate);
      endDateInUTC = getDateInUTC(config.forecastTotalToDate);
    }

    setRequest((req) => {
      switch (currentView) {
        case forecastViewType.BudgetView:
          const budgetViewFromDate = getDateInUTC(
            config.budgetViewForecastFromDate
          );
          const budgetViewToDate = getDateInUTC(
            config.budgetViewForecastToDate
          );
          return {
            path: "forecast",
            field: config.fields,
            selectedFacetsFilterData: selectedSearchData?.selectedFilterData,
            afterKey: { value: "", description: "" },
            query: "",
            unitOfMeasurements: selectedSearchData?.unitOfMeasurements,
            currency: selectedSearchData?.currency,
            fromDate: budgetViewFromDate,
            toDate: budgetViewToDate,
            forecastPercentageBaseField: config.forecastPercentageBaseField,
            forecastTotalFromDate: budgetViewFromDate,
            forecastTotalToDate: budgetViewToDate,
            forecastTotalByDateHeader:
              config.budgetViewForecastTotalByDateHeader,
            forecastTotalByDatePercentage:
              config.budgetViewForecastTotalByDatePercentage,
          };
        case forecastViewType.FutureView:
          const futureViewFromDate = getDateInUTC(
            config.futureViewForecastFromDate
          );
          const futureViewToDate = getDateInUTC(
            config.futureViewForecastToDate
          );
          return {
            path: "forecast",
            field: config.fields,
            selectedFacetsFilterData: selectedSearchData?.selectedFilterData,
            afterKey: { value: "", description: "" },
            query: "",
            unitOfMeasurements: selectedSearchData?.unitOfMeasurements,
            currency: selectedSearchData?.currency,
            fromDate: futureViewFromDate,
            toDate: futureViewToDate,
            forecastPercentageBaseField: config.forecastPercentageBaseField,
            forecastTotalFromDate: futureViewFromDate,
            forecastTotalToDate: futureViewToDate,
            forecastTotalByDateHeader:
              config.futureViewForecastTotalByDateHeader,
            forecastTotalByDatePercentage:
              config.futureViewForecastTotalByDatePercentage,
          };

        default:
          return {
            path: "forecast",
            field: config.fields,
            selectedFacetsFilterData: selectedSearchData?.selectedFilterData,
            afterKey: { value: "", description: "" },
            query: "",
            unitOfMeasurements: selectedSearchData?.unitOfMeasurements,
            currency: selectedSearchData?.currency,
            fromDate: subtractMonths(config.forecastFromMonths),
            toDate: addMonths(config.forecastToMonths),
            forecastTotalToMonths: config.forecastTotalToMonths,
            forecastYearToGo: config.forecastYearToGo,
            forecastTotalToMonthsPercentageDifference:
              config.forecastTotalToMonthsPercentageDifference,
            forecastYearToGoPercentageDifference:
              config.forecastYearToGoPercentageDifference,
            forecastPercentageBaseField: config.forecastPercentageBaseField,
            forecastTotalFromDate: startDateInUTC,
            forecastTotalToDate: endDateInUTC,
            forecastTotalByDateHeader: config.forecastTotalByDateHeader,
            forecastTotalByDatePercentage: config.forecastTotalByDatePercentage,
          };
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearchData, config, currentView]);

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    if (!request || !request.unitOfMeasurements) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    setLoading(true);

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await facetApi
            .list(accessToken, request)
            .then((data) => {
              setError(null);
              setAuthorizationError(null);
              return data;
            })
            .catch((e) => {
              if (e && e?.response?.status === 403) {
                setAuthorizationError(e);
              } else {
                setError(e);
              }

              console.log(e);
            })
            .finally(() => setLoading(false));
          setResults(data);
        })();
      })
      .catch((error) => {
        setError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, instance, accounts]);

  const handleAuthorizeEdit = (attributeConfiguration, action) => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await facetApi
            .authorizeEdit(accessToken, request)
            .then((data) => {
              setError(null);
              setAuthorizationError(null);
              return data;
            })
            .catch((e) => {
              if (e && e?.response?.status === 403) {
                setAuthorizationError(e);
              } else {
                setError(e);
              }

              console.log(e);
            })
            .finally(() => setLoading(false));
          if (data && data.isAuthorized) {
            handleEdit(attributeConfiguration, action);
          }
        })();
      })
      .catch((error) => {
        setError(error);
      });
  };
  const hasForecast = () => {
    return (
      results != null && results.forecast != null && results.forecast.length > 0
    );
  };

  const getForecastTableTemplate = () => {
    return config && isTrue(config.showForecastTotal) ? (
      <div className="row mt-4">
        <ForecastTable
          data={results.forecast}
          config={config}
          handleEdit={handleAuthorizeEdit}
          currentView={currentView}
          checkDataEditLock={checkDataEditLock}
          forecastRequest={{
            request: request,
            forecastDates: results.forecast.map((i) => i.label),
          }}
          isUserAuthorized={isUserAuthorized}
          currentAction={currentAction}
        />
      </div>
    ) : null;
  };

  const getForecastChartTemplate = () => {
    return (
      <div className="row">
        <ForecastChart
          data={results.forecast.filter((f) => !f.isTotal && !f.isPercentage)}
          config={config}
        />
      </div>
    );
  };

  if (error) {
    return <Error error={error} />;
  }

  return (
    <React.Fragment>
      <div className="forecast-container">
        {loading ? (
          <div className="row">
            <div className="col-12">
              <div className="p-5"></div>
              <div className="p-5">
                <Spinner />
              </div>
            </div>
          </div>
        ) : hasForecast() ? (
          <div className="col-12">
            {isTrue(config.showForecastTableFirst) ? (
              <React.Fragment>
                {getForecastTableTemplate()}
                {getForecastChartTemplate()}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getForecastChartTemplate()}
                {getForecastTableTemplate()}
              </React.Fragment>
            )}

            {config && isTrue(config.showItemAreaForecast) ? (
              <div className="row mt-4">
                <div className="col-12" key="forecasttable">
                  <div className="card shadow-sm rounded-extra-lg">
                    <h4 className="pt-3 ps-4">
                      {config.itemAreaTableHeader != null
                        ? config.itemAreaTableHeader
                        : "Forecast"}
                    </h4>
                    <div className="card-body">
                      <div className="mb-2 table-responsive scrollbar horizontal">
                        <div className="scrollbar-content">
                          <ItemAreaForecast
                            selectedSearchData={selectedSearchData}
                            handleEdit={handleEdit}
                            config={config}
                            fields={config?.fields}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="col-12 mt-3">
            <div className="text-center text-danger">
              No forecast data available for the selected filters
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Forecast;
