import React, { useEffect, useState } from "react";

import Dropdown from "../common/dropdown/dropdown";
import Error from "../common/ServerError";
import Spinner from "../common/Spinner";
import forecastApi from "../../api/forecastApi";
import { getStatusClass } from "../common/utilities";
import status from "../../constants/status";
import { tokenRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

const OverrideListing = () => {
  const { instance, accounts } = useMsal();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState([]);

  const initialRequest = {
    pageSize: 20,
    pageNumber: 1,
    refresh: true,
    searchQuery: "",
  };

  const [request, setRequest] = useState(initialRequest);

  const statusData = Object.values(status).map((v, d) => {
    return { value: v, description: v };
  });

  const overrideTypeData = [
    {
      value: "Manual",
      description: "Manual",
    },
    {
      value: "FileUpload",
      description: "File Upload",
    },
  ];

  const [selectedStatus, setSelectedStatus] = useState({
    value: "Status",
    description: "Status",
  });

  const [selectedOverrideType, setSelectedOverrideType] = useState({
    value: "Override Type",
    description: "Override Type",
  });

  const handleStatusDropdownSelection = (item) => {
    setSelectedStatus(item);
    setRequest((prev) => {
      return {
        ...prev,
        pageSize: 20,
        pageNumber: 1,
        status: item.value,
        refresh: true,
      };
    });
  };

  const handleOverrideTypeDropdownSelection = (item) => {
    setSelectedOverrideType(item);
    setRequest((prev) => {
      return {
        ...prev,
        pageSize: 20,
        pageNumber: 1,
        overrideType: item.value,
        refresh: true,
      };
    });
  };

  const [overrides, setOverrides] = useState({ results: [] });

  const handlePagination = (count) => {
    setRequest((prev) => {
      return {
        ...prev,
        refresh: true,
        pageSize: request.pageSize,
        pageNumber: request.pageNumber + count,
      };
    });
  };

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    setLoading(true);

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await forecastApi
            .overrideListing(accessToken, request)
            .then((data) => {
              setError(null);
              return data;
            })
            .catch((error) => {
              console.log(error);
              if (
                error.response?.status === 400 &&
                error.response?.data?.errors
              ) {
                let errors = [];
                for (const [key, value] of Object.entries(
                  error.response.data.errors
                )) {
                  errors.push({ name: key, value: value });
                }
                setErrorMessages(errors);
              } else {
                setError(error);
              }
            })
            .finally(() => setLoading(false));

          if (request.refresh) {
            setOverrides(data);
          } else {
            setOverrides((override) => {
              return {
                ...data,
                results: [...override.results, ...data.results],
              };
            });
          }
        })();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, [instance, accounts, request]);

  const createOverrideCard = (override) => {
    return (
      <div className="row" key={override.id}>
        <div className="col-12 mb-3">
          <div className="card shadow-sm rounded-extra-lg">
            <div className="card-body list-card">
              <div className="flex">
                <div className="name flex">{override.name}</div>
              </div>
              <div className="list-card values">
                <div key={override.id} className="value-container">
                  <div className="key">Id</div>
                  <div className="value">{override.id}</div>
                </div>
                <div className="value-container">
                  <div className="key">UoM</div>
                  <div className="value">{override.uoM}</div>
                </div>
                <div className="value-container">
                  <div className="key">Currency</div>
                  <div className="value">
                    {override.currency ? override.currency : "N/A"}
                  </div>
                </div>

                <div className="value-container">
                  <div className="key">Name</div>
                  <div className="value">
                    {override.overrideFileUpload?.name
                      ? override.overrideFileUpload.name
                      : "N/A"}
                  </div>
                </div>
                <div className="value-container">
                  <div className="key">Hierarchy</div>
                  <div className="value">
                    {override.overrideFileUpload?.hierarchy
                      ? override.overrideFileUpload.hierarchy
                      : "N/A"}
                  </div>
                </div>
                <div className="value-container">
                  <div className="key">Override Type</div>
                  <div className="value">
                    {override.isFileUpload ? "File Upload" : "Manual"}
                  </div>
                </div>
                <div className="value-container">
                  <div className="key">Status</div>
                  <div
                    className={`value badge rounded-pill text-bg-${getStatusClass(
                      override.status
                    )}`}
                  >
                    {override.status}
                  </div>
                </div>
                <div className="value-container">
                  <div className="key">Created</div>
                  <div className="value">
                    {new Date(override.created).toUTCString()}
                  </div>
                </div>
                <div className="value-container">
                  <div className="key">Modified</div>
                  <div className="value">
                    {new Date(override.modified).toUTCString()}
                  </div>
                </div>
                <div className="value-container">
                  <div className="key">User Name</div>
                  <div className="value">
                    {override.userName ? override.userName : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="row">
      <div className="col-12 my-3">
        <h4>Overrides</h4>
      </div>

      <div className="flex col-12 mb-3">
        <Dropdown
          disabled={false}
          data={statusData}
          buttonText={selectedStatus.description}
          header={"Status"}
          handleDropdownSelection={handleStatusDropdownSelection}
          position={"left"}
        />
        <Dropdown
          disabled={false}
          data={overrideTypeData}
          buttonText={selectedOverrideType.description}
          header={"Override Type"}
          handleDropdownSelection={handleOverrideTypeDropdownSelection}
          position={"left"}
        />
      </div>

      {overrides && overrides.results.length > 0 ? (
        <div className="col-12">
          {overrides.results.map((override) => createOverrideCard(override))}
          <div className="row py-3">
            <div className="flex middle">
              <button
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  handlePagination(-1);
                }}
                disabled={!overrides.hasPrevious}
              >
                Prev
              </button>
              <button
                className="ms-2 button"
                onClick={(e) => {
                  e.preventDefault();
                  handlePagination(1);
                }}
                disabled={!overrides.hasNext}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      ) : errorMessages?.length === 0 ? (
        <div className="col-12 text-center text-danger m-5">
          No overrides found
        </div>
      ) : null}

      <div className="row">
        {errorMessages && errorMessages.length > 0 ? (
          <div className="col-12 mt-3">
            {errorMessages.map((e) => {
              return <div className="mb-2 text-danger">{e.value}</div>;
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default OverrideListing;
