import * as React from "react";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { InteractionStatus } from "@azure/msal-browser";
import Unauthorize from "./Unauthorize";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  return isAuthenticated ? (
    children
  ) : inProgress !== InteractionStatus.Startup &&
    inProgress !== InteractionStatus.HandleRedirect ? (
    <Unauthorize />
  ) : null;
};

export default PrivateRoute;
