export function parseCliboardData(e, options) {
  const {
    rowSelector = "tr",
    cellSelector = "td",
    inputSelector = "input",
  } = options || {};

  if (!e || !e.target || !e.clipboardData) {
    return;
  }
  if (e.target.parentNode.querySelector(inputSelector) !== e.target) {
    return;
  }

  let currentInput = e.target;
  let currentCell = e.target.closest(cellSelector);
  let currentRow = e.target.closest(rowSelector);

  if (!currentCell | !currentRow) {
    return;
  }

  e.preventDefault();
  const startIndex = Array.from(
    currentRow.querySelectorAll(cellSelector)
  ).indexOf(currentCell);

  const copiedData = e.clipboardData.getData("text/plain");

  const data = copiedData
    .trim()
    .split(/\r\n|\n|\r/)
    .map((row) => row.split("\t"));

  for (const row of data) {
    for (const value of row) {
      currentInput.contentEditable === "true"
        ? (currentInput.textContent = value)
        : (currentInput.value = value);

      const inputEvent = new Event("input", { bubbles: true });
      currentInput.dispatchEvent(inputEvent);

      const blurEvent = new Event("blur", { bubbles: true });
      currentInput.dispatchEvent(blurEvent);

      currentCell = currentInput.closest(cellSelector);
      if (!currentCell || !currentCell.nextElementSibling) {
        break;
      }

      currentInput =
        currentCell.nextElementSibling.querySelector(inputSelector);
      if (!currentInput) {
        break;
      }
    }

    currentRow = currentRow.nextElementSibling;
    if (!currentRow) {
      break;
    }

    currentCell = currentRow.querySelectorAll(cellSelector)[startIndex];
    if (!currentCell) {
      break;
    }

    currentInput = currentCell.querySelector(inputSelector);
    if (!currentInput) {
      break;
    }
  }
}
