import api from "./revenuePlannerApi";

const path = "/index";

const searchIntegration = {
  get: (accessToken, dataIntegrationLogId) =>
    api.get(`${path}/${dataIntegrationLogId}`, accessToken),
};

export default searchIntegration;
