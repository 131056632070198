import { useEffect, useState } from "react";

import dataIntegrationApi from "../../api/dataIntegrationApi";
import { tokenRequest } from "../../authConfig";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const useDataIntegration = (dataIntegrationName) => {
  const { instance, accounts } = useMsal();
  const [
    errorWhileCheckingDataIntegration,
    setErrorWhileCheckingDataIntegration,
  ] = useState(null);
  const [dataIntegrationLog, setDataIntegrationLog] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await dataIntegrationApi
            .get(accessToken, dataIntegrationName)
            .then((data) => {
              setErrorWhileCheckingDataIntegration(null);
              setDataIntegrationLog(data);
            })
            .catch((e) => {
              setErrorWhileCheckingDataIntegration(e);
              console.log(e);
            });
        })();
      })
      .catch((error) => {
        setErrorWhileCheckingDataIntegration(error);
      });
  }, [instance, accounts, location, dataIntegrationName]);

  return { dataIntegrationLog, errorWhileCheckingDataIntegration };
};

export default useDataIntegration;
