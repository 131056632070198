import React from "react";

const Spinner = () => {
  return (
    <div className="col-12 d-flex justify-content-center">
      <div className="m-5">
        <div className="spinner-grow spinner-grow-1 me-1" role="status"></div>
        <div className="spinner-grow  spinner-grow-2 me-1" role="status"></div>
        <div className="spinner-grow me-1" role="status"></div>
      </div>
    </div>
  );
};

export default Spinner;
