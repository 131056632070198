const projectSteps = Object.freeze({
  None: "",
  Error: "Error",
  CreateProject: "CreateProject",
  CreateProjectItemArea: "CreateProjectItemArea",
  Details: "Details",
  EditProject: "EditProject",
  EditProjectItemArea: "EditProjectItemArea",
  ProjectCreated: "ProjectCreated",
});

export default projectSteps;
