import React from "react";

const Configuration = () => {
  return (
    <div className="row">
      <div className="col-12 m-3">
        <h1 className="">Configurations</h1>
      </div>
    </div>
  );
};

export default Configuration;
