import React from "react";
import { useLocation } from "react-router-dom";

export default function Forbidden({ error, hideRefresh, pathname }) {
  const location = useLocation();

  return (
    <div className="col-12 mt-4">
      <h2 className="lead text-center text-danger">FORBIDDEN</h2>

      {error?.response?.data.message ? (
        <React.Fragment>
          <p className="text-center text-danger">
            <span>{error.response.data.message}</span>
          </p>
          <div className="flex middle text-center">
            {!hideRefresh ? (
              <React.Fragment>
                <button
                  onClick={() => {
                    window.location.href = pathname ? pathname : location.pathname;
                  }}
                  className="button"
                >
                  Refresh
                </button>
              </React.Fragment>
            ) : null}
          </div>
        </React.Fragment>
      ) : (
        <p className="text-center">
          You do not have permission to view/modify this page.
        </p>
      )}

      <p className="text-center"></p>
    </div>
  );
}
