import React, { useState } from "react";

import Modal from "../common/modal/modal";
import actionType from "../../constants/actionType";
import distribution from "../../constants/distribution";
import overrideOptions from "../../constants/overrideOptions";
import { useForm } from "react-hook-form";
import valueType from "../../constants/valueType";

const OverrideOptions = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [advancedParameters, setAdvancedParameters] = useState(null);

  const handleOptionsSelection = (updatedItem) => {
    reset();
    setAdvancedParameters(null);
    const updatedOptions = props.options.map((item) => {
      return updatedItem === item
        ? { ...item, isSelected: true }
        : { ...item, isSelected: false };
    });
    props.setOptions(updatedOptions);

    if (props.setSelectedOptions) {
      props.setSelectedOptions(updatedItem);
    }
  };

  const handleOptionsCancel = () => {
    const updatedOptions = props.options.map((item) => {
      return { ...item, isSelected: false };
    });

    reset();
    setAdvancedParameters(null);

    if (props.setSelectedOptions) {
      props.setSelectedOptions(null);
    }

    props.setOptions(updatedOptions);
  };

  const anyOptionsSelected = () => {
    return props.options?.find((o) => o.isSelected);
  };

  const handleApply = async (data) => {
    if (Object.entries(data).length > 0 && props.setSelectedOptions) {
      setAdvancedParameters(data);

      props.setSelectedOptions((prevData) => {
        return {
          ...prevData,
          ...data,
        };
      });
    }

    props.handleOptionsModel(false);

    if (props.handleOk) {
      props.handleOk();
    }
  };

  const getOptionsTemplate = () => {
    return props.options.map((item) => {
      return item.featureEnabled ? (
        <form
          onSubmit={handleSubmit(handleApply)}
          key={`"options-parameters-form-"${item.value} `}
          encType="multipart/form-data"
          id="options-form"
        >
          <div className="form-group" key={item.value}>
            <label className="radio-field">
              <div className="mb-2">
                <input
                  className="radio"
                  type="radio"
                  id={item.value}
                  value={item.value}
                  name={props.header}
                  checked={item.isSelected ? true : false}
                  onChange={(e) =>
                    handleOptionsSelection(item, e.target.checked)
                  }
                />
                <span className="primary font-weight-medium">{item.value}</span>
              </div>
            </label>
            {item.label ? (
              <label className="form-label">{item.label}</label>
            ) : null}

            {item.isSelected &&
            item.value === overrideOptions.AdvancedOverrides ? (
              <div>
                <div className="form-group col-6">
                  <label className="form-label">Value</label>
                  <input
                    className={`me-2 form-control ${
                      errors.optionValue ? "is-invalid" : ""
                    }`}
                    name="optionValue"
                    {...register("optionValue", {
                      required: {
                        value: true,
                        message: "The value field is required.",
                      },
                    })}
                    type="number"
                  />
                  {errors.optionValue && (
                    <span className="invalid-feedback">
                      {errors.optionValue.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-6">
                  <label className="form-label">Value Type</label>
                  <select
                    name="valueType"
                    className="form-control"
                    {...register("valueType", {
                      required: {
                        value: false,
                        message: "",
                      },
                    })}
                  >
                    {Object.entries(valueType).map(([key, value]) => {
                      return (
                        <option value={value} key={value}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group col-6">
                  <label className="form-label">Distribution</label>
                  <select
                    name="distribution"
                    className="form-control"
                    {...register("distribution", {
                      required: {
                        value: false,
                        message: "",
                      },
                    })}
                  >
                    {Object.entries(distribution).map(([key, value]) => {
                      return (
                        <option value={value} key={value}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : null}
          </div>
        </form>
      ) : null;
    });
  };

  return (
    <React.Fragment>
      <div className="col-12">
        {props.showOptionsModal ? (
          <Modal
            actionType={actionType.Options}
            header={"Options"}
            subHeader={getOptionsTemplate()}
            setModalOpen={props.handleOptionsModel}
            handleCancel={handleOptionsCancel}
            handleOk={handleApply}
          />
        ) : (
          ""
        )}
        <div className="options">
          {anyOptionsSelected() ? (
            <React.Fragment>
              <div className="small-heading">Selected Option </div>
              <ul>
                {props.options
                  .filter((option) => option.isSelected)
                  .map((option) => {
                    return (
                      <li key={option.value}>
                        <span className="value text-danger">
                          {option.value}
                        </span>
                        {option.label ? (
                          <span className="label"> {option.label}</span>
                        ) : null}
                        {advancedParameters ? (
                          <span className="label">
                            {` Value - ${advancedParameters.optionValue}, Value Type - ${advancedParameters.valueType}, Distribution - ${advancedParameters.distribution}`}
                          </span>
                        ) : null}
                        <button
                          type="button"
                          className="ms-2 button small danger"
                          onClick={() => handleOptionsCancel()}
                        >
                          Cancel
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OverrideOptions;
