import React from "react";
import { useForm } from "react-hook-form";

const CreateProjectCategory = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    props.handleCreate(data);
  };

  return (
    <div className="col-6 mt-3">
      <div className="card shadow-sm rounded-extra-lg">
        <div className="card-body">
          <div className="my-3">
            <h4>Create</h4>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label className="form-label">Name</label>
              <input
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                name="name"
                {...register("name", {
                  required: {
                    value: true,
                    message: "The name field is required.",
                  },
                })}
              />
              {errors.name && (
                <span className="invalid-feedback">{errors.name.message}</span>
              )}
            </div>
            <div className="form-row">
              <div className="form-group col">
                <button type="submit" className="button primary me-2">
                  Create
                </button>
                <button
                  type="submit"
                  className="button danger"
                  onClick={() => props.setAdd(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateProjectCategory;
