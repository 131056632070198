import api from "./revenuePlannerApi";

const path = "/itemareas";

const itemArea = {
  list: (accessToken, body) =>
    api.post(`${path}/getitemareas`, body, accessToken),
};

export default itemArea;
