import api from "./revenuePlannerApi";

const path = "/projects";

const project = {
  validateProjectName: (accessToken, name, projectId = 0) =>
    api.get(`${path}/validate-projectname/${projectId}/${name}`, accessToken),
  fileUpload: function (accessToken, body, id) {
    if (id) {
      return api.put(`${path}/file-upload/${id}`, body, accessToken);
    }
    return api.post(`${path}/file-upload`, body, accessToken);
  },
  list: (accessToken, body) =>
    api.post(`${path}/getprojects`, body, accessToken),
  itemareas: (accessToken, body) =>
    api.post(`${path}/getitemareas`, body, accessToken),
  get: (accessToken, id) => api.get(`${path}/${id}`, accessToken),
  post: function (accessToken, body, id) {
    if (id) {
      return api.put(`${path}/${id}`, body, accessToken);
    }
    return api.post(path, body, accessToken);
  },
  authorize: (accessToken, id, isWriteOperation) =>
    api.get(`${path}/authorize/${id}/${isWriteOperation}`, accessToken),
  getByName: (accessToken, name, isWriteOperation) =>
    api.get(`${path}/${name}/${isWriteOperation}`, accessToken),
  put: (accessToken, id, body) => api.put(`${path}/${id}`, body, accessToken),
  delete: (accessToken, id) => api.delete(`${path}/${id}`, accessToken),
  configuration: (accessToken) => api.get(`${path}/configuration`, accessToken),
  processExpired: (accessToken) =>
  api.get(`${path}/process-expired`, accessToken),
};

export default project;
