export function isTrue(value) {
  return value !== undefined && value != null && value.toLowerCase() === "true";
}

export function subtractMonths(value) {
  const date = new Date();

  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth() - parseInt(value), 1)
  );
}

export function addMonths(value) {
  const date = new Date();

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth() + (value > 0 ? parseInt(value) : 11),
      1
    )
  );
}

export function formatDate(date) {
  return new Date(date).toLocaleDateString(process.env.REACT_APP_LOCALE);
}

export function getStatusClass(status) {
  if (!status) {
    return "";
  }

  switch (status) {
    case "Active":
    case "Completed":
    case "Processed":
      return "success";
    case "In Progress":
    case "InProgress":
    case "Processing":
    case "Expired":
      return "warning";
    case "Error":
    case "Failed":
      return "error";
    default:
      return status.toLowerCase();
  }
}

export function isDateInThePast(dateString) {
  const today = new Date();
  const date = new Date(dateString);

  const todayYear = today.getFullYear();
  const dateYear = date.getFullYear();

  if (dateYear < todayYear) {
    return true;
  }

  if (todayYear === dateYear) {
    const todayMonth = today.getMonth();
    const dateMonth = date.getMonth();

    if (dateMonth < todayMonth) {
      return true;
    }
  }

  return false;
}
