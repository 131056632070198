import api from "./revenuePlannerApi";

const path = "/dataintegration";

const dataIntegration = {
  get: (accessToken, dataIntegrationName) => {
    if (dataIntegrationName) {
      return api.get(`${path}/latest/${dataIntegrationName}`, accessToken);
    }
    return api.get(`${path}/latest`, accessToken);
  },
  runPipeline: (accessToken, pipelineName) =>
    api.get(`${path}/run-pipeline/${pipelineName}`, accessToken),
  runDataImportPipeline: (accessToken) =>
    api.get(`${path}/run-data-import-pipeline`, accessToken),
  runDataExportPipeline: (accessToken, pipelineName) => {
    if (pipelineName) {
      return api.get(
        `${path}/run-data-export-pipeline/${pipelineName}`,
        accessToken
      );
    }
    return api.get(`${path}/run-data-export-pipeline`, accessToken);
  },
};

export default dataIntegration;
