import { EventType, PublicClientApplication } from "@azure/msal-browser";

import App from "./components/app/App";
import { MsalProvider } from "@azure/msal-react";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createRoot } from "react-dom/client";
import { msalConfig } from "./authConfig";
import reportWebVitals from "./reportWebVitals";

export const history = createBrowserHistory();
const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Router navigator={history} location={history.location} forceRefresh={true}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
