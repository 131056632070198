import React, { useState } from "react";

import OverFlowToolTip from "../common/OverFlowToolTip";
import actionType from "../../constants/actionType";
import { formatNumber } from "../common/formatHelper";
import { isTrue } from "./../common/utilities";
import { useForm } from "react-hook-form";

function DbItemAreaForecastTable({ data, config, handleSave, currentAction }) {
  const [actions, setActions] = useState(
    currentAction?.attributeConfiguration != null
      ? {
          [currentAction.attributeConfiguration.attributeName]:
            currentAction.action,
          attributeName: currentAction.attributeConfiguration.attributeName,
          areaCode: currentAction.areaCode,
          item: currentAction.item,
          entityName: currentAction.attributeConfiguration.parent
        }
      : {}
  );

  const {
    register: itemAreaForecastRegister,
    formState: { errors: itemAreaForecastErrors },
    handleSubmit: itemAreaForecastHandleSubmit,
    reset,
  } = useForm();

  const onItemAreaForecastSaveHandler = async (dataToBeSaved) => {
    let forecastTimeHorizon = [];

    for (const [key, value] of Object.entries(dataToBeSaved)) {
      const forecast = actions.item.forecast.find((f) => f.id === key);
      const originalValue = forecast[actions.attributeName.toLowerCase()];

      // eslint-disable-next-line eqeqeq
      if (originalValue != value) {
        forecastTimeHorizon.push({
          timeBucket: forecast.forecastdate,
          value: value,
        });
      }
    }

    handleSave(
      forecastTimeHorizon,
      actions.attributeName,
      actions.entityName,
      actions.areaCode,
      actions.item
    );
  };

  const getActionTemplate = (attributeConfiguration, areaCode, item) => {
    const selectedActionType =
      actions &&
      actions[attributeConfiguration.attributeName] === actionType.Edit &&
      actions.item?.itemCode === item.itemCode &&
      actions.areaCode === areaCode
        ? actionType.Edit
        : actionType.Cancel;

    switch (selectedActionType) {
      case actionType.Edit:
        return (
          <div className="flex">
            <button type="submit" className="link bold underline">
              <span>Save</span>
            </button>
            <button
              type="button"
              className="link bold underline clear"
              onClick={(e) =>
                handleAction(
                  e,
                  attributeConfiguration,
                  actionType.Cancel,
                  areaCode,
                  item
                )
              }
            >
              <span>Cancel</span>
            </button>
          </div>
        );

      default:
        return (
          <div className="flex">
            <button
              type="button"
              className="link bold underline"
              onClick={(e) =>
                handleAction(
                  e,
                  attributeConfiguration,
                  actionType.Edit,
                  areaCode,
                  item
                )
              }
            >
              <span>Edit</span>
            </button>
            <button
              type="button"
              className="link bold underline clear"
              disabled={true}
            >
              <span>Cancel</span>
            </button>
          </div>
        );
    }
  };

  const handleAction = (e, attributeConfiguration, action, areaCode, item) => {
    e.preventDefault();

    if (action === actionType.Cancel) {
      reset();
    }
    setActions((prevActions) => {
      return {
        ...prevActions,
        [attributeConfiguration.attributeName]: action,
        attributeName: attributeConfiguration.attributeName,
        areaCode: areaCode,
        item: item,
      };
    });
  };

  const getTemplate = () => {
    const dateLables = data.labels.map((label) => {
      return (
        <th
          scope="col"
          style={{ width: "7rem" }}
          className="text-end"
          key={label}
        >
          {label}
        </th>
      );
    });

    const values = data.forecast.map((area) => {
      return area.forecast.map((itemForecast) => {
        return config.attributeConfigurations.map((c) => {
          return (
            <tr key={c.attributeName + itemForecast.itemCode + area.areaCode}>
              <th scope="col" className="name">
                <div className="flex">
                  <div
                    className={
                      isTrue(config.isItemAreaForecastEditable) ? "actions" : ""
                    }
                  >
                    {c.isEditable &&
                    isTrue(config.isItemAreaForecastEditable) ? (
                      getActionTemplate(c, area.areaCode, itemForecast)
                    ) : (
                      <span> </span>
                    )}
                  </div>
                  <div style={{ width: "10rem" }}>
                    <OverFlowToolTip>{area.areaCode}</OverFlowToolTip>
                  </div>
                </div>
              </th>
              <th scope="col" className="name">
                <div className="flex">
                  <div style={{ width: "20rem" }}>
                    <OverFlowToolTip>{itemForecast.itemCode}</OverFlowToolTip>
                  </div>
                </div>
              </th>
              <th scope="col" className="name">
                <div className="flex">
                  <div style={{ width: "10rem" }}>
                    <span> {c.displayName}</span>
                  </div>
                </div>
              </th>

              {itemForecast.forecast.map((forecast) => {
                return c.isEditable &&
                  actions &&
                  actions[c.attributeName] === actionType.Edit &&
                  actions.item?.itemCode === forecast.itemCode &&
                  actions.areaCode === area.areaCode &&
                  !forecast.isPastForecast ? (
                  <td className="editable text-end" key={forecast.id}>
                    <div>
                      <input
                        className={`text-end form-control ${
                          itemAreaForecastErrors?.[forecast.id]
                            ? "is-invalid"
                            : "input-focus"
                        } ${
                          c.attributeName.toLowerCase() ===
                            "statisticalforecast" &&
                          forecast.isOverrideInProcess
                            ? "danger text-danger"
                            : ""
                        }`}
                        name={forecast.id}
                        type="number"
                        {...itemAreaForecastRegister(`${forecast.id}`, {
                          required: {
                            value: true,
                            message: `The ${forecast.label} field is required.`,
                          },
                        })}
                        step="any"
                        defaultValue={forecast[c.attributeName.toLowerCase()]}
                      />
                      {itemAreaForecastErrors?.[forecast.id] && (
                        <span className="invalid-feedback">
                          {itemAreaForecastErrors?.[forecast.id]?.message}
                        </span>
                      )}
                    </div>
                  </td>
                ) : (
                  <td className="text-end" key={forecast.id}>
                    <div
                      style={{ width: "7rem" }}
                      className={`${
                        c.attributeName.toLowerCase() ===
                          "statisticalforecast" && forecast.isOverrideInProcess
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      <OverFlowToolTip>
                        {formatNumber(
                          forecast[c.attributeName.toLowerCase()],
                          config.decimalPlaces
                        )}
                      </OverFlowToolTip>
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        });
      });
    });

    return (
      <React.Fragment>
        <table className="forecast itemarea results table table-lg table-striped-custom">
          <thead>
            <tr>
              <th scope="col">
                <div className="flex">
                  <div
                    className={
                      isTrue(config.isItemAreaForecastEditable) ? "actions" : ""
                    }
                  ></div>
                  <div className="text-start">{config.areaCodeHeader}</div>
                </div>
              </th>
              <th scope="col">
                <div className="flex">{config.itemCodeHeader}</div>
              </th>
              <th scope="col">
                <div className="flex">
                  {config.attributeDisplayNameHeader != null
                    ? config.attributeDisplayNameHeader
                    : "Name"}
                </div>
              </th>
              {dateLables}
            </tr>
          </thead>
          <tbody>{values}</tbody>
        </table>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <form
        onSubmit={itemAreaForecastHandleSubmit(onItemAreaForecastSaveHandler)}
        key="create-project-time-horizon-parameters"
      >
        {getTemplate()}
      </form>
    </React.Fragment>
  );
}

export default DbItemAreaForecastTable;
