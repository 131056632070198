import React, { useEffect, useState } from "react";
import { addMonths, subtractMonths } from "../common/utilities";

import Error from "../common/ServerError";
import OverFlowToolTip from "../common/OverFlowToolTip";
import Spinner from "../common/Spinner";
import dateHelper from "../common/dateHelper";
import facetApi from "../../api/facetApi";
import { formatNumber } from "../common/formatHelper";
import { tokenRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

const ForecastAuditSearchListing = ({
  configuration,
  selectedSearchData,
  columns,
  currentFilterAttributeName,
  handleShowAuthorizationErrorModel,
  setAuthorizationError,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [results, setResults] = useState({});
  const [request, setRequest] = useState(null);
  const { instance, accounts } = useMsal();
  const [, , getDateInUTC] = dateHelper();

  useEffect(() => {
    if (
      !configuration ||
      !selectedSearchData ||
      !selectedSearchData.unitOfMeasurements
    ) {
      return;
    }

    let startDateInUTC = new Date();
    let endDateInUTC = addMonths(12);
    if (
      configuration.forecastTotalFromDate != null &&
      configuration.forecastTotalToDate != null
    ) {
      startDateInUTC = getDateInUTC(configuration.forecastTotalFromDate);
      endDateInUTC = getDateInUTC(configuration.forecastTotalToDate);
    }

    const reqObject = {
      path: "forecastaudit",
      field: "forecastdate",
      selectedFacetsFilterData: selectedSearchData?.selectedFilterData
        ? selectedSearchData.selectedFilterData
        : [],
      afterKey: { value: "", description: "" },
      query: "",
      unitOfMeasurements: selectedSearchData?.unitOfMeasurements,
      currency: selectedSearchData?.currency,
      fromDate: subtractMonths(
        configuration.forecastFromMonths ? configuration.forecastFromMonths : 0
      ),
      toDate: addMonths(
        configuration.forecastToMonths ? configuration.forecastToMonths : 12
      ),
      forecastTotalToMonths: configuration.forecastTotalToMonths
        ? configuration.forecastTotalToMonths
        : 12,
      forecastYearToGo: configuration.forecastYearToGo,
      forecastTotalToMonthsPercentageDifference:
        configuration.forecastTotalToMonthsPercentageDifference
          ? configuration.forecastTotalToMonthsPercentageDifference
          : false,
      forecastYearToGoPercentageDifference:
        configuration.forecastYearToGoPercentageDifference
          ? configuration.forecastYearToGoPercentageDifference
          : false,
      forecastPercentageBaseField: configuration.forecastPercentageBaseField
        ? configuration.forecastPercentageBaseField
        : null,
      forecastTotalFromDate: startDateInUTC,
      forecastTotalToDate: endDateInUTC,
      forecastTotalByDateHeader: configuration.forecastTotalByDateHeader
        ? configuration.forecastTotalByDateHeader
        : null,
      forecastTotalByDatePercentage: configuration.forecastTotalByDatePercentage
        ? configuration.forecastTotalByDatePercentage
        : false,
      searchForecastAudit: true,
      pageNumber: 1,
      pageSize: 20,
      refresh: true,
    };

    setRequest((req) => {
      return reqObject;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearchData, configuration]);

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    if (!request || !request.unitOfMeasurements) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    setLoading(true);

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await facetApi
            .list(accessToken, request)
            .then((data) => {
              setError(null);
              setAuthorizationError(null);
              return data;
            })
            .catch((e) => {
              if (e && e?.response?.status === 403) {
                handleShowAuthorizationErrorModel(e);
              } else {
                setError(e);
              }

              console.log(e);
            })
            .finally(() => setLoading(false));
          if (request.refresh) {
            setResults(data);
          } else {
            setResults((result) => {
              return {
                ...data,
                rows: [...result.rows, ...data.rows],
              };
            });
          }
        })();
      })
      .catch((error) => {
        setError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, instance, accounts]);

  const handleNext = () => {
    setRequest((prev) => {
      return {
        ...prev,
        refresh: false,
        pageSize: request.pageSize,
        pageNumber: request.pageNumber + 1,
        isResponseUpdate: false,
      };
    });
  };

  const createTable = () => {
    const rows = results.rows.map((row, i) => {
      const cells = row.cells.map((cell, j) => {
        const column = columns.find((c) => c.value === cell.header);
        const isSelected = column ? (column.isSelected ? true : false) : true;

        return isSelected ? (
          <td
            key={`${i}${j}`}
            className={`${
              cell.dataType === "Decimal" ? "text-end" : "text-left"
            }`}
          >
            <div className={`cell ${cell.cssClass}`}>
              {cell.dataType === "Decimal" ? (
                <OverFlowToolTip>
                  {formatNumber(cell.value, configuration.decimalPlaces)}
                </OverFlowToolTip>
              ) : (
                <OverFlowToolTip>{cell.value}</OverFlowToolTip>
              )}
            </div>
          </td>
        ) : null;
      });
      return <tr key={`${i}`}>{cells}</tr>;
    });

    const headers = results.headers.map((header) => {
      const column = columns.find((c) => c.value === header.header);
      const isSelected = column ? (column.isSelected ? true : false) : true;

      return isSelected ? (
        <th
          scope="col"
          className={`${
            header.dataType === "Decimal" ? "text-end" : "text-left"
          }`}
          key={header.header}
        >
          <div className={`cell ${header.cssClass}`}>{header.header}</div>
        </th>
      ) : null;
    });

    return (
      <div className="mb-2 table-responsive scrollbar horizontal show">
        <div className="scrollbar-content fixed-height">
          <table className="project-values table table-striped-custom table-borderless table-header-border table-collapse">
            <thead className="sticky-top top-0">
              <tr>{headers}</tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  };

  if (error) {
    return <Error error={error} />;
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className="row">
          <div className="col-12">
            <div className="p-5"></div>
            <div className="p-5">
              <Spinner />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          {results && results.rows && results.rows.length > 0 ? (
            <React.Fragment>
              {createTable()}
              <div className="row pb-3">
                <div className="flex middle">
                  <button
                    className="button"
                    onClick={() => handleNext()}
                    disabled={!results.hasNext}
                  >
                    Load More
                  </button>
                </div>
              </div>
            </React.Fragment>
          ) : results.message ? (
            <React.Fragment>
              <h4 className="text-center text-error">{results.message}</h4>
              <p className="text-center text-grey-500">
                Please contact support to setup
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h2 className="text-center">No results found</h2>
              <p className="text-center text-grey-500">
                Try adjusting your applied filters or clear all
              </p>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ForecastAuditSearchListing;
