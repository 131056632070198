import api from "./revenuePlannerApi";

const path = "/authorization";

const authorization = {
  getClaims: (accessToken) => {
    return api.get(`${path}/claims`, accessToken);
  },
};

export default authorization;
