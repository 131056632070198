import api from "./revenuePlannerApi";

const forecastConfiguration = {
  list: (accessToken) => api.get("/forecastconfiguration", accessToken),
  results: (accessToken) =>
    api.get("/forecastconfiguration/results", accessToken),
  auditConfiguration: (accessToken) =>
    api.get("/forecastconfiguration/audit-configuration", accessToken),
};

export default forecastConfiguration;
