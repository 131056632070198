import React, { useEffect, useState } from "react";
import { addMonths, isTrue, subtractMonths } from "../common/utilities";

import Error from "../common/ServerError";
import OverFlowToolTip from "../common/OverFlowToolTip";
import Spinner from "../common/Spinner";
import actionType from "../../constants/actionType";
import facetApi from "../../api/facetApi";
import { formatNumber } from "../common/formatHelper";
import { tokenRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

const ItemAreaForecast = ({
  selectedSearchData,
  handleEdit,
  config,
  fields,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [request, setRequest] = useState(null);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (!config && !isTrue(config.showItemAreaForecast)) {
      return;
    }

    setRequest((req) => {
      if (req == null) {
        return {
          path: "forecast",
          field: fields,
          selectedFacetsFilterData: selectedSearchData?.selectedFilterData,
          afterKey: { value: "", description: "" },
          query: "",
          unitOfMeasurements: selectedSearchData?.unitOfMeasurements,
          currency: selectedSearchData?.currency,
          itemAreaLevel: true,
          fromDate: subtractMonths(config.forecastFromMonths),
          toDate: addMonths(config.forecastToMonths),
        };
      } else if (
        JSON.stringify(req.selectedFacetsFilterData) !==
          JSON.stringify(selectedSearchData) ||
        JSON.stringify(req.unitOfMeasurements) !==
          JSON.stringify(selectedSearchData.unitOfMeasurements) ||
        JSON.stringify(req.currency) !==
          JSON.stringify(selectedSearchData.currency)
      ) {
        return {
          ...req,
          selectedFacetsFilterData: selectedSearchData.selectedFilterData,
          unitOfMeasurements: selectedSearchData.unitOfMeasurements,
          currency: selectedSearchData.currency,
        };
      } else {
        return {
          ...req,
        };
      }
    });
  }, [selectedSearchData, config, fields]);

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    if (!request || !request.unitOfMeasurements) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const response = await facetApi
            .list(accessToken, request)
            .then((data) => {
              setError(false);
              return data;
            })
            .catch((e) => {
              setError(true);
              console.log(e);
            })
            .finally(() => setLoading(false));
          setData(response);
        })();
      })
      .catch((error) => {
        setError(true);
      });
  }, [request, instance, accounts]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const getTemplate = () => {
    const dateLables = data.labels.map((label) => {
      return (
        <th
          scope="col"
          style={{ width: "6rem" }}
          className="text-end"
          key={label}
        >
          {label}
        </th>
      );
    });

    const values = data.forecast.map((area) => {
      return area.forecast.map((itemForecast) => {
        return config.attributeConfigurations.map((c) => {
          return (
            <tr key={c.attributeName}>
              <th scope="col" className="name">
                <div className="flex">
                  <div
                    className={
                      isTrue(config.isItemAreaForecastEditable)
                        ? "actions small"
                        : ""
                    }
                  >
                    {c.isEditable &&
                    isTrue(config.isItemAreaForecastEditable) ? (
                      <button
                        className="link bold underline"
                        onClick={() =>
                          handleEdit(
                            c,
                            actionType.Edit,
                            area.areaCode,
                            itemForecast
                          )
                        }
                      >
                        <span>Edit</span>
                      </button>
                    ) : (
                      <span> </span>
                    )}
                  </div>
                  <div style={{ width: "10rem" }}>
                    <OverFlowToolTip>{area.areaCode}</OverFlowToolTip>
                  </div>
                </div>
              </th>
              <th scope="col" className="name">
                <div className="flex">
                  <div style={{ width: "20rem" }}>
                    <OverFlowToolTip>{itemForecast.itemCode}</OverFlowToolTip>
                  </div>
                </div>
              </th>
              <th scope="col" className="name">
                <div className="flex">
                  <div style={{ width: "10rem" }}>
                    <span> {c.displayName}</span>
                  </div>
                </div>
              </th>

              {itemForecast.forecast.map((forecast) => {
                return (
                  <td className="text-end" key={forecast.id}>
                    <div
                      style={{ width: "6rem" }}
                      className={`${
                        c.attributeName.toLowerCase() ===
                          "statisticalforecast" && forecast.isOverrideInProcess
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      <OverFlowToolTip>
                        {formatNumber(
                          forecast[c.attributeName.toLowerCase()],
                          config.decimalPlaces
                        )}
                      </OverFlowToolTip>
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        });
      });
    });

    return (
      <React.Fragment>
        <table className="forecast itemarea table table-lg table-striped-custom">
          <thead>
            <tr>
              <th scope="col">
                <div className="flex">
                  <div
                    className={
                      isTrue(config.isItemAreaForecastEditable)
                        ? "actions small"
                        : ""
                    }
                  ></div>
                  <div className="text-start">{config.areaCodeHeader}</div>
                </div>
              </th>
              <th scope="col">
                <div className="flex">{config.itemCodeHeader}</div>
              </th>
              <th scope="col">
                <div className="flex">
                  {config.attributeDisplayNameHeader != null
                    ? config.attributeDisplayNameHeader
                    : "Name"}
                </div>
              </th>
              {dateLables}
            </tr>
          </thead>
          <tbody>{values}</tbody>
        </table>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {data != null && data.forecast ? (
        <React.Fragment>{getTemplate()}</React.Fragment>
      ) : (
        <div className="col-12 mt-3">
          <div className="text-center text-danger">
            No forecast data available for the selected filters
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ItemAreaForecast;
