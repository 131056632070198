import * as XLSX from "xlsx";

import { ReactComponent as ExcelFileIcon } from "../../../components/icons/file_type_xlsx.svg";
import React from "react";

export const FileExport = ({ apiData, fileName, sheetName }) => {
    const exportFile = (apiData, fileName) => {

    const worksheet = XLSX.utils.json_to_sheet(apiData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      sheetName ? sheetName : "Forecast"
    );

    worksheet["!cols"] = [{ wch: 16 }];
    XLSX.writeFile(workbook, `${fileName}.xlsx`, { compression: true });
  };

  return (
    <button
      type="button"
      className="link ms-2"
      onClick={(e) => exportFile(apiData, fileName)}
    >
      <ExcelFileIcon />
    </button>
  );
};
