import Modal from "../common/modal/modal";
import React from "react";
import actionType from "../../constants/actionType";
import status from "../../constants/status";

export default function DataIntegrationStatus({
  dataIntegrationLog,
  errorWhileCheckingDataIntegration,
}) {
  function getTemplate() {
    var actionTypeValue;
    var headerValue;
    var subHeaderValue;

    if (errorWhileCheckingDataIntegration) {
      actionTypeValue = actionType.Error;
      headerValue = "Error";
      subHeaderValue =
        "An unexpected error occurred while checking for data integration status, please try again later.";
    } else {
      switch (dataIntegrationLog.status) {
        case status.InProgress:
          actionTypeValue = actionType.Warning;
          headerValue = `${dataIntegrationLog.name} is in progress`;
          subHeaderValue = "Please wait for sometime";
          break;
        case status.Failed:
          actionTypeValue = actionType.Error;
          headerValue = `${dataIntegrationLog.name} failed`;
          subHeaderValue =
            "An error occurred while integrating data, please contact support.";
          break;
        default:
          break;
      }
    }

    return (
      <Modal
        actionType={actionTypeValue}
        header={headerValue}
        subHeader={subHeaderValue}
      />
    );
  }

  return <div className="col-12">{getTemplate()}</div>;
}
