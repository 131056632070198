import api from "./revenuePlannerApi";

const forecast = {
  list: (accessToken, body) => api.post("/forecast", body, accessToken),
  projectList: (accessToken, body) =>
    api.post("/forecast/projects", body, accessToken),
  override: (accessToken, body) =>
    api.post("/forecast/override", body, accessToken),
  overrideListing: (accessToken, body) =>
    api.post("/forecast/override-logs", body, accessToken),
};

export default forecast;
