import api from "./revenuePlannerApi";

const overrideFileUpload = {
  configuration: (accessToken) =>
    api.get("/override-fileUpload/configuration", accessToken),
  post: (accessToken, body) =>
    api.post("/override-fileUpload", body, accessToken),
};

export default overrideFileUpload;
