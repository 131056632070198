import React, { useEffect, useRef, useState } from "react";

const OverFlwoToolTip = ({ children }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const elementRef = useRef();

  useEffect(() => {
    const isOverFlow = () => {
      const result =
        elementRef.current.scrollWidth > elementRef.current.clientWidth;
      setIsOverflow(result);
    };

    isOverFlow();
    window.addEventListener("resize", isOverFlow);
    return () => {
      window.removeEventListener("resize", isOverFlow);
    };
  }, []);

  return (
    <React.Fragment>
      <div
        title={isOverflowed ? children : ""}
        ref={elementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export default OverFlwoToolTip;
