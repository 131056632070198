import React, { useEffect, useRef, useState } from "react";

import Error from "../common/ServerError";
import { ReactComponent as HelpIcon } from "../../components/icons/help_circle_icon.svg";
import Modal from "../common/modal/modal";
import { ReactComponent as OptionsIcon } from "../../components/icons/list_ul_options_icon.svg";
import OverrideOptions from "../override-options/OverrideOptions";
import Spinner from "../common/Spinner";
import actionType from "../../constants/actionType";
import hierarchy from "../../constants/hierarchy";
import { isTrue } from "../common/utilities";
import overrideFileUpload from "../../api/overrideFileUploadApi";
import overrideOptions from "../../constants/overrideOptions";
import { tokenRequest } from "../../authConfig";
import unitOfMeasurements from "../../constants/unitOfMeasurements";
import uomConfigApi from "../../api/uomConfigApi";
import uomData from "../../constants/uoMData";
import { useForm } from "react-hook-form";
import { useMsal } from "@azure/msal-react";

const FileUpload = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const { instance, accounts } = useMsal();
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [
    overrideFileUploadConfigurationError,
    setOverrideFileUploadConfigurationError,
  ] = useState();
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);

  const fileInputRef = useRef(null);
  const { ref, ...rest } = register("file");

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [currencyData, setCurrencyData] = useState([]);
  const [showCurrency, setShowCurrency] = useState(false);
  const [overrideFileUploadConfiguration, setOverrideFileUploadConfiguration] =
    useState(null);

  const [showHelp, setShowHelp] = useState(false);

  const [options, setOptions] = useState([
    {
      value: overrideOptions.Revert,
      description: overrideOptions.Revert,
      label:
        "Overrides will be replaced with the last imported statistical forecast or overrides if exist for the selection",
      isSelected: false,
      featureEnabled: true,
    },
    {
      value: overrideOptions.RemoveOverrides,
      description: overrideOptions.RemoveOverrides,
      label:
        "Overrides will be replaced with the statistical forecast for the selection",
      isSelected: false,
      featureEnabled: true,
    },
  ]);

  const [showOptionsModal, setShowOptionsModal] = useState(false);

  const handleOptionsModel = () => {
    setShowOptionsModal((showOptionsModal) => {
      return !showOptionsModal;
    });
  };

  const handleOk = () => {
    console.log();
  };

  const anyOptionsSelected = () => {
    return options.find((o) => o.isSelected);
  };

  const resetOptions = () => {
    const updatedOptions = options.map((item) => {
      return { ...item, isSelected: false };
    });

    setOptions(updatedOptions);
  };

  const saveFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setFileName(file.name);
    }
  };

  useEffect(() => {
    if (response) {
      if (response.status?.toLowerCase() === "success") {
        reset({
          hierarchy: null,
          uoM: null,
          currency: null,
          file: null,
        });
        setShowCurrency(false);
        resetOptions();
      } else if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const handleUomDropdownSelection = (event) => {
    const result =
      event.target.value === unitOfMeasurements.COST ||
      event.target.value === unitOfMeasurements.PRICE;

    if (!result) {
      reset({
        ...getValues(),
        currency: null,
      });
    }
    setShowCurrency(result);
  };

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    setLoading(true);

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await overrideFileUpload
            .configuration(accessToken)
            .then((data) => {
              setOverrideFileUploadConfigurationError(null);
              setOverrideFileUploadConfiguration(data);
            })
            .catch((e) => {
              setOverrideFileUploadConfigurationError(e);
              console.log(e);
            })
            .finally(() => setLoading(false));
        })();
      })
      .catch((error) => {
        setOverrideFileUploadConfigurationError(error);
        setLoading(false);
      });
  }, [instance, accounts]);

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await uomConfigApi
            .list(accessToken)
            .then((data) => {
              setError(null);
              setCurrencyData(data.currency);
            })
            .catch((e) => {
              setError(e);
              console.log(e);
            })
            .finally();
        })();
      })
      .catch((e) => {
        setError(e);
      });
  }, [instance, accounts]);

  const handleFileUploadSubmit = (d) => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileName", fileName);

    if (
      overrideFileUploadConfiguration &&
      isTrue(overrideFileUploadConfiguration.showHierarchy)
    ) {
      formData.append("hierarchy", d.hierarchy);
    } else {
      formData.append("hierarchy", hierarchy[0].value);
    }

    formData.append("uoM", d.uoM);
    if (d.currency) {
      formData.append("currency", d.currency);
    }

    if (anyOptionsSelected()) {
      const selectedOption = options.find((option) => option.isSelected);

      switch (selectedOption.value) {
        case overrideOptions.Revert:
          formData.append("revert", selectedOption.isSelected);
          break;
        case overrideOptions.RemoveOverrides:
          formData.append("removeOverrides", selectedOption.isSelected);
          break;

        default:
          break;
      }
    }

    setResponse(null);
    setErrorMessages([]);
    setError(null);
    setOverrideFileUploadConfigurationError(null);
    setUploading(true);

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await overrideFileUpload
            .post(accessToken, formData)
            .then((data) => {
              setError(null);
              setErrorMessages([]);
              return data;
            })
            .catch((e) => {
              console.log(e);
              if (e.response?.status === 400 && e.response?.data?.errors) {
                let errors = [];
                for (const [key, value] of Object.entries(
                  e.response.data.errors
                )) {
                  errors.push({ name: key, value: value });
                }
                setErrorMessages(errors);
              } else {
                setError(e);
              }
            })
            .finally(() => setUploading(false));

          setResponse(data);
          reset();
        })();
      })
      .catch((error) => {
        setError(error);
        setUploading(false);
      });
  };

  const handleShowHelp = () => {
    setShowHelp((showHelp) => {
      return !showHelp;
    });
  };

  const onSubmit = async (data) => {
    const lockEdit = props.checkDataEditLock(
      overrideFileUploadConfiguration.enableEditLock,
      setErrorMessages
    );

    if (lockEdit) {
      return;
    }

    handleFileUploadSubmit(data);
  };

  const getHelp = () => {
    return (
      <div className="animate fadeIn slow col-12">
        <h5>File Uploads</h5>
        <ul>
          <li>
            The override files should be uploaded in CSV (comma separated
            values) format.
          </li>
          <li>
            The CSV file's header must exactly match the Schema. The order of
            the columns must be the same as specified in the Schema.
          </li>
          <li>
            User can edit the file in Excel or in any other application and must
            save it as .CSV file.
          </li>
          <li>
            If any header is missing, a message with missing header name will be
            displayed.
          </li>
          <li>
            Number of rows within the file should not exceed the configured
            threshold value. Threshold value is the total number of rows within
            the file.
          </li>
          <li>
            If the rows are duplicated in the file, a message with duplicated
            records will be displayed.
          </li>
        </ul>
        <h5>Schema</h5>
        <h6>CSV File Header</h6>
        <div className="doc-container">
          <dl>
            <dt>ItemArea Hierarchy</dt>
            <dd>
              <code>AreaCode,ItemCode,Year,Month,Quantity</code>
              <pre>AC1,IC1,2023,9,100</pre>
              <div className="row">
                <div className="col-6">
                  <table className="table table-sm table-striped-custom table-bordered ">
                    <thead>
                      <tr>
                        <th scope="col">AreaCode</th>
                        <th scope="col">ItemCode</th>
                        <th scope="col">Year</th>
                        <th scope="col">Month</th>
                        <th scope="col">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>AC1</td>
                        <td>IC1</td>
                        <td>2023</td>
                        <td>9</td>
                        <td>100</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </dd>

            <dt>MacroArea Hierarchy</dt>
            <dd>
              <code>MacroArea,ItemCode,Year,Month,Quantity</code>
              <pre>MA1,IC1,2023,9,100</pre>
              <div className="row">
                <div className="col-6">
                  <table className="table table-sm table-striped-custom table-bordered ">
                    <thead>
                      <tr>
                        <th scope="col">MacroArea</th>
                        <th scope="col">ItemCode</th>
                        <th scope="col">Year</th>
                        <th scope="col">Month</th>
                        <th scope="col">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>MA1</td>
                        <td>IC1</td>
                        <td>2023</td>
                        <td>9</td>
                        <td>100</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </dd>
          </dl>
        </div>
        <h5>Hierarchy</h5>
        <div className="doc-container">
          <dl>
            <dt>ItemArea</dt>
            <dd>Commercial Overrides will be saved by the Quantity.</dd>
            <dt>MacroArea</dt>
            <dd>
              Commercial Overrides will be saved by disaggregating Quantity
              based on the Statistical Forecast.
            </dd>
          </dl>
        </div>
        <h5>Unit Of Measurements (UoM)</h5>
        <ul>
          <li>UoM works same as manual overrides.</li>
          <li>Example item/area record</li>
          <div className="row">
            <div className="mt-3 col-6">
              <table className="table table-sm table-striped-custom table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">AreaCode</th>
                    <th scope="col">ItemCode</th>
                    <th scope="col">Commercial Override</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AC1</td>
                    <td>IC1</td>
                    <td>50</td>
                    <td>10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <li>
            A file is uploaded with
            <span className="font-weight-medium"> UoM Volume</span> as shown
            below
          </li>
          <div className="row">
            <div className="mt-3 col-6">
              <table className="table table-sm table-striped-custom table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">AreaCode</th>
                    <th scope="col">ItemCode</th>
                    <th scope="col">Year</th>
                    <th scope="col">Month</th>
                    <th scope="col">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AC1</td>
                    <td>IC1</td>
                    <td>2023</td>
                    <td>9</td>
                    <td>100</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <li>
            Commercial Override will be saved as
            <span className="font-weight-medium"> 100.</span>
          </li>
          <div className="row">
            <div className="mt-3 col-6">
              <table className="table table-sm table-striped-custom table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">AreaCode</th>
                    <th scope="col">ItemCode</th>
                    <th scope="col">Commercial Override</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AC1</td>
                    <td>IC1</td>
                    <td>100</td>
                    <td>10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <li>
            In Search page{" "}
            <span className="font-weight-medium"> UoM Volume </span> will show
            Commercial Override as{" "}
            <span className="font-weight-medium"> 100 </span> and{" "}
            <span className="font-weight-medium"> UoM Price </span> will show
            Commercial Override as
            <span className="font-weight-medium"> 1000.</span>
          </li>
          <li>
            A file is uploaded with
            <span className="font-weight-medium"> UoM Price</span> as shown
            below
          </li>
          <div className="row">
            <div className="mt-3 col-6">
              <table className="table table-sm table-striped-custom table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">AreaCode</th>
                    <th scope="col">ItemCode</th>
                    <th scope="col">Year</th>
                    <th scope="col">Month</th>
                    <th scope="col">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AC1</td>
                    <td>IC1</td>
                    <td>2023</td>
                    <td>9</td>
                    <td>200</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <li>
            Commercial Override will be saved as
            <span className="font-weight-medium"> 20. </span>(This is calculated
            with item/area Price 10)
          </li>
          <div className="row">
            <div className="mt-3 col-6">
              <table className="table table-sm table-striped-custom table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">AreaCode</th>
                    <th scope="col">ItemCode</th>
                    <th scope="col">Commercial Override</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AC1</td>
                    <td>IC1</td>
                    <td>20</td>
                    <td>10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <li>
            In Search page{" "}
            <span className="font-weight-medium"> UoM Volume </span> will show
            Commercial Override as{" "}
            <span className="font-weight-medium"> 20 </span> and{" "}
            <span className="font-weight-medium"> UoM Price </span> will show
            Commercial Override as
            <span className="font-weight-medium"> 200.</span>
          </li>
        </ul>
        <h5>Options</h5>
        <h6>Revert</h6>
        <ul>
          <li>
            Commercial Overrides will be replaced with the last imported (e.g.,
            Weekly Monday Data Import) statistical forecast or commercial
            overrides if exist for the selection
          </li>
          <li>
            User must add{" "}
            <span className="font-weight-medium">
              ONLY the rows that need to be reverted
            </span>{" "}
            in the file, not all the rows. Quantity column should be in the file
            but the value is ignored
          </li>
        </ul>
        <h6>Remove Overrides</h6>
        <ul>
          <li>
            Commercial Overrides will be replaced with the last imported (e.g.,
            Weekly Monday Data Import) statistical forecast for the selection
          </li>
          <li>
            User must add{" "}
            <span className="font-weight-medium">
              ONLY the rows that overrides need to be removed
            </span>{" "}
            in the file, not all the rows. Quantity column should be in the file
            but the value is ignored
          </li>
        </ul>
      </div>
    );
  };

  const getFileUploadErrorRecordsTemplate = (response) => {
    return (
      <div className="mt-2">
        <table className="table table-sm table-striped-custom">
          <thead>
            <tr>
              <th>
                {response.hierarchy === hierarchy[0].value
                  ? "AreaCode"
                  : "MacroArea"}
              </th>
              <th>ItemCode</th>
              <th>Year</th>
              <th>Month</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {response.records.map((row, key) => {
              return (
                <tr key={key}>
                  <td>
                    {response.hierarchy === hierarchy[0].value
                      ? row.areaCode
                      : row.macroArea}
                  </td>
                  <td>{row.itemCode}</td>
                  <td>{row.year}</td>
                  <td>{row.month}</td>
                  <td>{row.quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  if (loading) {
    return <Spinner />;
  }

  if (overrideFileUploadConfigurationError) {
    return <Error error={overrideFileUploadConfigurationError} />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <React.Fragment>
      <div className="row ps-2">
        <div className="col-6">
          {showHelp ? (
            <Modal
              actionType={actionType.Help}
              content={getHelp()}
              handleCancel={handleShowHelp}
              setModalOpen={setShowHelp}
            />
          ) : null}
          <div className="my-3">
            <div className="flex">
              <div className="flex">
                <h4>
                  Upload Forecast Overrides{" "}
                  <span role="button" onClick={handleShowHelp}>
                    <HelpIcon />
                  </span>
                </h4>
              </div>
              <div>
                <button
                  type="button"
                  className="link"
                  onClick={handleOptionsModel}
                >
                  <span className="pe-1">
                    <OptionsIcon />
                  </span>
                  <span className="font-weight-medium">Options</span>
                </button>
              </div>
            </div>
          </div>
          <OverrideOptions
            showOptionsModal={showOptionsModal}
            handleOptionsModel={handleOptionsModel}
            setOptions={setOptions}
            options={options}
            handleOk={handleOk}
          />
          <div className="" key="create-project-parameters-container">
            <form
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              {overrideFileUploadConfiguration != null &&
              isTrue(overrideFileUploadConfiguration.showHierarchy) ? (
                <div className="form-group">
                  <label className="form-label">Hierarchy</label>
                  <select
                    name="hierarchy"
                    className={`form-control ${
                      errors.hierarchy ? "is-invalid" : ""
                    }`}
                    {...register("hierarchy", {
                      required: {
                        value: true,
                        message: "The hierarchy field is required.",
                      },
                    })}
                  >
                    <option value="">Select Hierarchy</option>
                    {hierarchy.map((option) => {
                      return (
                        <option value={option.value} key={option.value}>
                          {option.description}
                        </option>
                      );
                    })}
                  </select>
                  {errors.hierarchy && (
                    <span className="invalid-feedback">
                      {errors.hierarchy.message}
                    </span>
                  )}
                </div>
              ) : null}

              <div className="form-group">
                <label className="form-label">UoM</label>
                <select
                  name="uoM"
                  className={`form-control ${errors.uoM ? "is-invalid" : ""}`}
                  {...register("uoM", {
                    onChange: (e) => handleUomDropdownSelection(e),
                    required: {
                      value: true,
                      message: "The uom field is required.",
                    },
                  })}
                >
                  <option value="">Select UoM</option>
                  {uomData.map((option) => {
                    return (
                      <option value={option.value} key={option.value}>
                        {option.description}
                      </option>
                    );
                  })}
                </select>
                {errors.uoM && (
                  <span className="invalid-feedback">{errors.uoM.message}</span>
                )}
              </div>

              {showCurrency ? (
                <div className="form-group">
                  <label className="form-label">Currency</label>
                  <select
                    name="currency"
                    className={`form-control ${
                      errors.currency ? "is-invalid" : ""
                    }`}
                    {...register("currency", {
                      required: {
                        value: true,
                        message: "The currency field is required.",
                      },
                    })}
                  >
                    <option value="">Select Currency</option>
                    {currencyData.map((option) => {
                      return (
                        <option
                          value={option.description}
                          key={option.description}
                        >
                          {option.description}
                        </option>
                      );
                    })}
                  </select>
                  {errors.currency && (
                    <span className="invalid-feedback">
                      {errors.currency.message}
                    </span>
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="form-group">
                <label className="form-label">CSV File</label>
                <input
                  {...rest}
                  type="file"
                  className={`form-control ${errors.file ? "is-invalid" : ""}`}
                  name="file"
                  {...register("file", {
                    required: {
                      value: true,
                      message: "The file field is required.",
                    },
                  })}
                  onChange={saveFile}
                  ref={(e) => {
                    ref(e);
                    fileInputRef.current = e;
                  }}
                />
                {errors.file && (
                  <span className="invalid-feedback">
                    {errors.file.message}
                  </span>
                )}
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <button
                    type="submit"
                    className="button primary me-2"
                    disabled={uploading}
                  >
                    Upload
                  </button>
                </div>
              </div>
              {uploading ? (
                <span>
                  <span className="me-2 font-weight-medium">Uploading</span>
                  <span
                    className="spinner-grow spinner-grow-1 me-1"
                    role="status"
                  ></span>
                  <span
                    className="spinner-grow spinner-grow-2 me-1"
                    role="status"
                  ></span>
                  <span className="spinner-grow me-1" role="status"></span>
                </span>
              ) : null}
            </form>

            {response ? (
              <div>
                <div className={`flex text-${response?.status?.toLowerCase()}`}>
                  {response?.message}
                </div>

                {response.records
                  ? getFileUploadErrorRecordsTemplate(response)
                  : null}
              </div>
            ) : null}

            <div>
              {errorMessages && errorMessages.length > 0 ? (
                <div className="">
                  {errorMessages.map((e) => {
                    return (
                      <div key={e.name} className="mb-2 text-danger">
                        {e.value}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FileUpload;
