import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as BackIcon } from "../../../components/icons/arrow_icon.svg";

const Slider = ({ sliderElements, sliderRightAdditionalElement }) => {
  let sliderElementsContainer = useRef(null);
  const [scrollStart, setScrollStart] = useState(0);

  const isScrollEnd = () => {
    return (
      Math.floor(
        sliderElementsContainer?.current?.scrollWidth -
          sliderElementsContainer?.current?.scrollLeft
      ) <= sliderElementsContainer?.current?.offsetWidth
    );
  };
  const [scrollEnd, setScrollEnd] = useState(isScrollEnd());

  const slide = (slideBy) => {
    sliderElementsContainer.current.scrollBy({
      left: slideBy,
      behavior: "smooth",
    });

    sliderElementsContainer.current.scrollLeft += slideBy;
    setScrollStart(scrollStart + slideBy);
    setScrollEnd(isScrollEnd());
  };

  useEffect(() => {
    const setScroll = () => {
      setScrollStart(sliderElementsContainer.current.scrollLeft);
      setScrollEnd(isScrollEnd());
    };

    setScroll();

    const handleScroll = () => {
      setScroll();
    };

    const container = sliderElementsContainer.current;
    container.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);
    };
  }, [sliderElements]);

  return (
    <div className="slider">
      <button
        className={`left-arrow ms-n2 button flex scale circle circle-small ${
          scrollStart < 1 ? "hidden" : ""
        }`}
        onClick={() => slide(-sliderElementsContainer.current.clientWidth)}
      >
        <div className="arrow-icon back">
          <BackIcon />
        </div>
      </button>

      <div
        ref={sliderElementsContainer}
        className={`item-container ${!scrollEnd ? "right-margin" : ""} ${
          !scrollStart < 1 ? "left-margin" : ""
        }`}
      >
        {sliderElements}
      </div>

      <button
        className={`right-arrow ${
          !sliderRightAdditionalElement ? "arrow-margin" : ""
        } button flex scale circle circle-small ${scrollEnd ? "hidden" : ""}`}
        onClick={() => slide(+sliderElementsContainer.current.clientWidth)}
      >
        <div className="arrow-icon forward">
          <BackIcon />
        </div>
      </button>
      {sliderRightAdditionalElement}
    </div>
  );
};

export default Slider;
