import React, { useEffect, useState } from "react";

import Dropdown from "../common/dropdown/dropdown";
import Error from "../common/ServerError";
import Spinner from "../common/Spinner";
import { formatNumber } from "../common/formatHelper";
import itemAreaApi from "../../api/itemAreaApi";
import projectApi from "../../api/projectApi";
import { toLocaleDateString } from "../common/formatHelper";
import { tokenRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

const ItemArea = ({
  selectedSearchData,
  currentProject,
  setSelectedItemAreaData,
  detailView,
  setAuthorizationError,
  configuration,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingProjectItemAreasMore, setLoadingProjectItemAreasMore] =
    useState(false);
  const [loadingProjectItemAreas, setLoadingProjectItemAreas] = useState(false);
  const [error, setError] = useState(false);
  const [itemAreas, setItemAreas] = useState({ results: [] });
  const [request, setRequest] = useState(null);
  const { instance, accounts } = useMsal();
  const [selectAll, setSelectAll] = useState(
    currentProject?.projectItemAreaSelection?.itemAreaSelectionContainer
      ?.selectAll
      ? true
      : false
  );
  const [project, setProject] = useState(currentProject);
  const [userItemAreaSelections, setUserItemAreaSelections] = useState(
    currentProject?.projectItemAreaSelection?.itemAreaSelectionContainer
      ?.itemAreaSelections != null
      ? currentProject.projectItemAreaSelection.itemAreaSelectionContainer
          .itemAreaSelections
      : []
  );

  const [includeZeros, setIncludeZeros] = useState(false);

  const initialProjectItemAreaRequest = {
    projectId: currentProject.id,
    pageSize: 10,
    pageNumber: 1,
    refresh: false,
    includeZeros: includeZeros,
  };

  const [projectItemAreaRequest, setProjectItemAreaRequest] = useState(
    initialProjectItemAreaRequest
  );
  const [projectItemAreas, setProjectItemAreas] = useState({ results: [] });

  useEffect(() => {
    if (
      detailView ||
      !selectedSearchData ||
      !selectedSearchData.selectedFilterData
    ) {
      return;
    }

    const initialRequest = {
      pageSize: 10,
      pageNumber: 1,
      refresh: false,
      projectId: currentProject.id,
    };

    const filters =
      selectedSearchData != null &&
      selectedSearchData.selectedFilterData != null
        ? selectedSearchData.selectedFilterData.map((filterData) => {
            return {
              parent: filterData.path,
              field: filterData.field,
              items: filterData.items,
            };
          })
        : [];

    if (selectedSearchData?.clearAll) {
      setSelectAll(false);
      setUserItemAreaSelections([]);
      setProject((project) => {
        return {
          ...project,
          projectItemAreaSelection: {
            ...project.projectItemAreaSelection,
            itemAreaSelectionContainer: {
              selectAll: false,
              itemAreaSelections: [],
            },
          },
        };
      });
    }

    setRequest((req) => {
      return {
        ...initialRequest,
        filters,
      };
    });
  }, [selectedSearchData, detailView, currentProject]);

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    if (!request || request.filters == null) {
      return;
    }

    if (request.pageNumber > 1) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await itemAreaApi
            .list(accessToken, request)
            .then((data) => {
              setError(null);
              setAuthorizationError(null);
              return data;
            })
            .catch((error) => {
              setError(error);
              setAuthorizationError(error);
              console.log(error);
            })
            .finally(() => {
              if (request.pageNumber > 1) {
                setLoadingMore(false);
              } else {
                setLoading(false);
              }
            });

          const itemAreasWithSelectedFlag =
            data.results != null
              ? data.results.map((ia) => {
                  let isCurrentlySelected = false;

                  if (!selectedSearchData?.clearAll) {
                    const overrideItemArea = userItemAreaSelections.find(
                      (override) =>
                        override.itemCode === ia.itemCode &&
                        override.areaCode === ia.areaCode
                    );

                    isCurrentlySelected =
                      overrideItemArea != null
                        ? overrideItemArea.isSelected
                        : selectAll;
                  }

                  return isCurrentlySelected
                    ? { ...ia, isSelected: true }
                    : { ...ia, isSelected: false };
                })
              : [];

          if (request.refresh || request.pageNumber === 1) {
            setItemAreas({ ...data, results: itemAreasWithSelectedFlag });
          } else {
            setItemAreas((itemAreas) => {
              return {
                ...data,
                results: [...itemAreas.results, ...itemAreasWithSelectedFlag],
              };
            });
          }
        })();
      })
      .catch((error) => {
        setError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, project, instance, accounts]);

  useEffect(() => {
    if (
      !detailView ||
      !instance ||
      !accounts ||
      accounts.length === 0 ||
      !projectItemAreaRequest.projectId
    ) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    if (projectItemAreaRequest.pageNumber > 1) {
      setLoadingProjectItemAreasMore(true);
    } else {
      setLoadingProjectItemAreas(true);
    }

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await projectApi
            .itemareas(accessToken, projectItemAreaRequest)
            .then((data) => {
              setError(null);
              if (projectItemAreaRequest.refresh) {
                setProjectItemAreas(data);
              } else {
                setProjectItemAreas((itemArea) => {
                  return {
                    ...data,
                    results: [...itemArea.results, ...data.results],
                  };
                });
              }
            })
            .catch((error) => {
              setError(error);
              console.log(error);
            })
            .finally(() => {
              if (projectItemAreaRequest.pageNumber > 1) {
                setLoadingProjectItemAreasMore(false);
              } else {
                setLoadingProjectItemAreas(false);
              }
            });
        })();
      })
      .catch((error) => {
        setLoadingProjectItemAreasMore(false);
        setError(error);
      });
  }, [instance, accounts, projectItemAreaRequest, detailView]);

  useEffect(() => {
    if (!setSelectedItemAreaData) {
      return;
    }

    const updatedItemAreas = selectAll
      ? userItemAreaSelections.filter((ia) => !ia.isSelected)
      : userItemAreaSelections;

    const itemAreaSelections =
      updatedItemAreas != null && updatedItemAreas.length > 0
        ? updatedItemAreas.map((itemArea) => {
            return {
              itemCode: itemArea.itemCode,
              areaCode: itemArea.areaCode,
              isSelected: itemArea.isSelected,
            };
          })
        : [];

    setSelectedItemAreaData({
      selectAll,
      itemAreaSelections,
    });
  }, [userItemAreaSelections, setSelectedItemAreaData, selectAll]);

  const handleNext = () => {
    setRequest({
      ...request,
      selectAll: selectAll,
      pageNumber: request.pageNumber + 1,
    });
  };

  const handleProjectItemAreaNext = () => {
    setProjectItemAreaRequest({
      ...projectItemAreaRequest,
      selectAll: selectAll,
      refresh: false,
      pageNumber: projectItemAreaRequest.pageNumber + 1,
    });
  };

  const handleFilterCheckboxSelection = (updatedItemArea, isSelected) => {
    if (updatedItemArea) {
      const itemAreaSelectionsWithoutCurrentSelection =
        userItemAreaSelections.filter((override) => {
          return !(
            updatedItemArea.itemCode === override.itemCode &&
            updatedItemArea.areaCode === override.areaCode
          );
        });

      const updatedItemAreaSelection = {
        ...updatedItemArea,
        isSelected: isSelected,
      };

      setUserItemAreaSelections([
        ...itemAreaSelectionsWithoutCurrentSelection,
        updatedItemAreaSelection,
      ]);

      const updatedResults = itemAreas.results.map((itemArea) => {
        return updatedItemArea === itemArea
          ? {
              ...itemArea,
              isSelected: isSelected,
            }
          : itemArea;
      });

      setItemAreas((itemAreas) => {
        return {
          ...itemAreas,
          results: [...updatedResults],
        };
      });
    }
  };

  const handleSelectAllFilterCheckboxSelection = (isSelected) => {
    setSelectAll(isSelected);
    const updatedResults = itemAreas.results.map((itemArea) => {
      return {
        ...itemArea,
        isSelected: isSelected,
      };
    });

    setUserItemAreaSelections([]);

    setItemAreas((itemAreas) => {
      return {
        ...itemAreas,
        results: [...updatedResults],
      };
    });
  };

  const handleIncludeZerosCheckboxSelection = (includeZeros) => {
    setIncludeZeros(includeZeros);
    setProjectItemAreaRequest((prevRequest) => {
      return {
        ...prevRequest,
        pageNumber: 1,
        refresh: true,
        includeZeros: includeZeros,
      };
    });
  };

  const getColumns = () => {
    return [
      {
        value: "Include Zeros",
        description: "Include Zeros",
        isSelected: false,
      },
      {
        value: "Item Description",
        description: "Item Description",
        isSelected: false,
      },
    ];
  };

  const [columns, setColumns] = useState(getColumns);

  const handleColumnSelection = (updatedData) => {
    const isIncludeZerosSelectedFlag = updatedData.find(
      (d) => d.value === "Include Zeros"
    ).isSelected;

    handleIncludeZerosCheckboxSelection(isIncludeZerosSelectedFlag);
    setColumns(updatedData);
  };

  if (error) {
    return <Error error={error} hideRefresh={true} />;
  }

  const itemAreaTemplate = (itemAreas) => {
    if (!configuration) {
      return;
    }

    const isDescriptionSelected = columns.find(
      (c) => c.value === "Item Description"
    ).isSelected;

    const getValueHeaders = () => {
      return configuration.attributeConfigurations.map((c) => {
        return (
          <React.Fragment
            key={"value-header" + c.attributeName + c.displayName}
          >
            <th scope="col" className="text-end" style={{ minWidth: "10rem" }}>
              {c.displayName}
            </th>
            <th scope="col" className="text-end" style={{ minWidth: "10rem" }}>
              {c.attributeName.toLowerCase() === "value"
                ? " Disaggregated Value"
                : c.displayName + " Disaggregated Value"}
            </th>
            <th scope="col" className="text-end" style={{ minWidth: "10rem" }}>
              {c.attributeName.toLowerCase() === "value"
                ? " Disaggregated Value In Units"
                : c.displayName + " Disaggregated Value In Units"}
            </th>
          </React.Fragment>
        );
      });
    };

    const getValues = (itemArea) => {
      return configuration.attributeConfigurations.map((c) => {
        return (
          <React.Fragment
            key={
              "value" +
              c.attributeName +
              c.displayName +
              itemArea.itemCode +
              itemArea.areaCode +
              itemArea.timeBucket
            }
          >
            <td className="text-end">
              {formatNumber(
                itemArea[c.attributeName.toLowerCase()],
                configuration.decimalPlaces
              )}
            </td>
            <td className="text-end">
              {formatNumber(
                itemArea[
                  c.attributeName.toLowerCase() === "value"
                    ? "disaggregatedValue"
                    : c.attributeName.toLowerCase() + "DisaggregatedValue"
                ],
                configuration.decimalPlaces
              )}
            </td>
            <td className="text-end">
              {formatNumber(
                itemArea[
                  c.attributeName.toLowerCase() === "value"
                    ? "disaggregatedValueInUnits"
                    : c.attributeName.toLowerCase() +
                      "DisaggregatedValueInUnits"
                ],
                configuration.decimalPlaces
              )}
            </td>
          </React.Fragment>
        );
      });
    };

    const rows = itemAreas.results.map((itemArea, index) => {
      return (
        <tr
          key={`${index} ${itemArea.itemCode} ${itemArea.areaCode} ${itemArea.timeBucket}`}
        >
          {detailView ? null : (
            <th scope="row">
              <label className="checkbox-field">
                <div className="checkbox-container">
                  <input
                    className="input"
                    type="checkbox"
                    name={itemArea.value}
                    checked={itemArea.isSelected ? true : false}
                    onChange={(e) =>
                      handleFilterCheckboxSelection(itemArea, e.target.checked)
                    }
                  />
                  <span></span>
                </div>
              </label>
            </th>
          )}

          <td>
            {itemArea.itemCode}{" "}
            {isDescriptionSelected
              ? "(" + itemArea.itemDescription + ")"
              : null}
          </td>
          <td>
            {itemArea.areaCode} ({itemArea.areaDescription})
          </td>
          {detailView ? (
            <React.Fragment>
              <td>{toLocaleDateString(itemArea.timeBucket)}</td>
              {getValues(itemArea)}
            </React.Fragment>
          ) : null}
        </tr>
      );
    });

    return (
      <table className="table table-striped-custom table-borderless table-header-border table-collapse">
        <thead className="sticky-top top-0">
          <tr key={"header-row"}>
            {detailView ? null : (
              <th scope="col" className="small-width">
                <label className="checkbox-field">
                  <div className="checkbox-container">
                    <input
                      className="input"
                      type="checkbox"
                      name="select-all"
                      checked={selectAll}
                      onChange={(e) =>
                        handleSelectAllFilterCheckboxSelection(e.target.checked)
                      }
                    />
                    <span>Select all</span>
                  </div>
                </label>
              </th>
            )}

            <th
              scope="col"
              style={{ minWidth: isDescriptionSelected ? "26rem" : "12rem" }}
            >
              Item
            </th>
            <th scope="col" style={{ minWidth: "12rem" }}>
              Area
            </th>
            {detailView ? (
              <React.Fragment>
                <th scope="col" style={{ minWidth: "8rem" }}>
                  Time Bucket
                </th>
                {getValueHeaders()}
              </React.Fragment>
            ) : null}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  return (
    <React.Fragment>
      {detailView ? (
        <React.Fragment>
          <div className="flex">
            <h5 className="flex">Item Areas</h5>
            <div className="flex flex-end form-group mn-1 ps-2">
              <Dropdown
                disabled={false}
                data={columns}
                buttonText={"Customize Columns"}
                header={"Columns"}
                handleDropdownSelection={handleColumnSelection}
                scrollbar={true}
                multiSelection={true}
              />
            </div>
          </div>
          <div className="row">
            {loadingProjectItemAreas ? (
              <Spinner />
            ) : projectItemAreas &&
              projectItemAreas.results &&
              projectItemAreas.results.length > 0 ? (
              <React.Fragment>
                <div className="animate fadeIn slow mb-2 table-responsive scrollbar horizontal show">
                  <div className="scrollbar-content fixed-height">
                    <div className="col">
                      {itemAreaTemplate(projectItemAreas)}
                    </div>
                    <div className="row py-3">
                      <div className="flex middle">
                        <button
                          className="button"
                          onClick={() => handleProjectItemAreaNext()}
                          disabled={!projectItemAreas.hasNext}
                        >
                          Load More
                          {loadingProjectItemAreasMore ? (
                            <span className="ms-2">
                              <span
                                className="spinner-grow spinner-grow-1 me-1"
                                role="status"
                              ></span>
                              <span
                                className="spinner-grow  spinner-grow-2 me-1"
                                role="status"
                              ></span>
                              <span
                                className="spinner-grow me-1"
                                role="status"
                              ></span>
                            </span>
                          ) : null}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className="col-12 m-3">
                <div className="text-center text-warning">
                  No item areas found. Select include zeros to show results with
                  value 0.
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      ) : (
        ""
      )}

      <React.Fragment>
        {loading ? (
          <Spinner />
        ) : itemAreas && itemAreas.results && itemAreas.results.length > 0 ? (
          <div className="card shadow-sm rounded-extra-lg mb-5">
            <div className="card-body project-card">
              <h5>Item Areas</h5>
              <div className="row">
                <div className="col">{itemAreaTemplate(itemAreas)}</div>
              </div>
              <div className="row py-3">
                <div className="flex middle">
                  <button
                    className="button"
                    onClick={() => handleNext()}
                    disabled={!itemAreas.hasNext}
                  >
                    Load More
                    {loadingMore ? (
                      <span className="ms-2">
                        <span
                          className="spinner-grow spinner-grow-1 me-1"
                          role="status"
                        ></span>
                        <span
                          className="spinner-grow  spinner-grow-2 me-1"
                          role="status"
                        ></span>
                        <span
                          className="spinner-grow me-1"
                          role="status"
                        ></span>
                      </span>
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : detailView ? (
          ""
        ) : (
          <div className="col-12 mt-3">
            <div className="text-center text-danger">
              No item areas data available for the selected filters
            </div>
          </div>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default ItemArea;
